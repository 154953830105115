%Montserrat-light {
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
}

%Montserrat-regular {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
}

@font-face {
    font-family: 'Butler';
    font-display: block;
    src: url('../fonts/Butler_Regular.otf');
    font-weight: 400;
}
 