.box-text {
    position: relative;
    padding: 65px 145px 75px;

    @media all and (min-width: 641px) and (max-width: 991px) {
        padding: 65px;
    }

    @media all and (max-width: 640px) {
        padding: 30px;
    }

    &--small-padding {
        padding: 20px 20px 40px 20px;
    }

    &--small-on-mobile {
        @media all and (max-width: 768px) {
            padding: 20px;
        }

        .letters {
            @media all and (max-width: 768px) {
                margin-top: 0;
            }
        }
    }

    &--bordered {
        border: 1px solid $colorGold;
    }

    &--link {
        display: block;
        height: 100%;
        border-color: $colorGray;
        cursor: pointer;
        transition: border-color 0.2s;

        .box-text__btn {
            text-align: center;
            width: 120px;
            transition: width 1s, border-color 0.2s;
        }

        &:hover {
            border-color: $colorGold;

            .box-text__btn {
                width: calc(100% - 80px);
                border-color: $colorGold;
            }
        }
    }

    &-wrapper {
        padding: 90px 100px;

        @media all and (min-width: 641px) and (max-width: 991px) {
            padding: 65px;
        }

        @media all and (max-width: 640px) {
            padding: 20px;
        }
    }

    &-slogan {
        margin: {
            right: 0;
            left: auto;
        }
        padding: 60px 25px;
        max-width: 480px;
        border: 1px solid $colorGold;
        background-color: $colorBlack;

        @media all and (max-width: 991px) {
            margin: {
                right: auto;
            }
            padding: 25px;
        }

        &--to-right {

            @media all and (min-width: 1450px) {
                position: relative;
                margin-right: -20%;
            }
        }
    }

    &__footer {
        margin-top: 120px;

        @media all and (max-width: 768px) {
            margin-top: 48px;
        }

        .btn {
            width: 450px;
            margin-top: 1em;
            margin-bottom: 50px;
            text-align: center;

            @media all and (max-width: 768px) {
                width: 100%;
                margin-bottom: 0;
            }
        }
    }

    &__btn {
        position: absolute;
        bottom: -33.5px;
        left: 50%;
        transform: translateX(-50%);

        &.btn--small {
            bottom: -21.5px;
        }
    }

    &__image-wrapper {
        margin-bottom: 20px;

        &.card:not(.card--not-shrink):before {
            padding-top: 100%;
        }
    }
}

div.box-text--link {
    display: block;
    cursor: auto;
}
