$mainContainerMaxWidth: 1456;
$tinyContainerMaxWidth: 544;
$superNarrowContainerMaxWidth: 718;
$narrowContainerMaxWidth: 865;
$mediumNarrowContainerMaxWidth: 965;
$mediumContainerMaxWidth: 1210;
$largeContainerMaxWidth: 1700;
$mLargeContainerMaxWidth: 1550;

 
.container {
    position: relative;
    margin-right: auto;
    margin-left: auto;

    width: 92%;
    max-width: ($mainContainerMaxWidth)+px;

    &-tiny {
        @extend .container;
        max-width: ($tinyContainerMaxWidth)+px;
    }

    &-super-narrow {
        @extend .container;
        max-width: ($superNarrowContainerMaxWidth)+px;
    }

    &-narrow {
        @extend .container;
        max-width: ($narrowContainerMaxWidth)+px;
    }

    &-medium-narrow {
        @extend .container;
        max-width: ($mediumNarrowContainerMaxWidth)+px;
    }

    &-medium {
        @extend .container;
        max-width: ($mediumContainerMaxWidth)+px;
    }

    &-mlarge {
        @extend .container;
        max-width: ($mLargeContainerMaxWidth)+px;
    }

    &-large {
        @extend .container;
        max-width: ($largeContainerMaxWidth)+px;
    }

    &-fluid {
        padding-left: 0;
        padding-right: 0;
        overflow-x: hidden;
    }
}
