/**
*
* Content (text stylisation connected to CMS WYSIWYG editor)
*
**/
.content {
    @extend %Montserrat-light;

    p,
    li,
    td {
        line-height: 1.5;
        font-size: $defaultFontSize;
    }
    
    .content__small-text,
    &--small-text p,
    &--small-text li {
        font-size: $smallFontSize;
    }

    .content__large-text,
    &--large-text p,
    &--large-text li {
        font-size: $largeFontSize;
    }

    p:not(:last-child) {
        margin: 0;
        padding-bottom: .9em;
    }

    ul:not(:last-child),
    ol:not(:last-child) {
        padding-bottom: 1em;
    }

    p {
        margin-top: 0;

        &:last-child {
            margin-bottom: 0;
        }
    }

    p + ul,
    p + ol {
        //margin-top: -.7em !important;
    }

    a:not(.btn) {
        color: $colorGold;
        text-decoration: underline;
        transition: opacity 1s cubic-bezier(0.3, 0.00, 0.2, 1);

        &:hover {
            opacity: 0.8;
        }
    }

    /* uls, ols */
    ul {
        @extend .list-ul;
    }

    ol {
        @extend .list-ol;
    }

    blockquote {
        @extend .blockquote;
    }

    img:not([src*=".svg"]) {
        height: auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: {
            top: 1.2em;
            bottom: 1.2em;
        }
    }

    picture {
        img {
          min-width: 100%;
        }
    }
}