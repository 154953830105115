/**
*
* Headlines
*
**/

%commonStyles {
    margin: 0 0 0.6em 0;
    line-height: 1.1;
    font-family: "Butler", serif;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.2em;
}

.headline {
    &-1 {
        @extend %commonStyles;

        @include dynamicFontSize(32, 48, 480, 1600);
    }

    &-2 {
        @extend %commonStyles;

        @include dynamicFontSize(32, 44 , 480, 1600);
    }

    &-3 {
        @extend %commonStyles;

        @include dynamicFontSize(24, 36, 480, 1600);
    }

    &-4 {
        @extend %commonStyles;

        @include dynamicFontSize(24, 32, 480, 1600);
    }

    &-5 {
        @extend %commonStyles;

        @include dynamicFontSize(18, 24, 480, 1600);
    }

    &-6 {
        @extend %Montserrat-regular;

        @include dynamicFontSize(16, 18, 480, 1600);
        margin: 0 0 0.2em 0;
        line-height: 1.1;
        text-transform: uppercase;
        letter-spacing: 0.2em;

    }

    &-center {

        &-tablet {
            @media all and (max-width: 901px) {
                justify-content: center;
            }
        }
    }

    &-p-left {

        @media all and (min-width: 1501px) {
            padding-left: 80px;
        }

        @media all and (min-width: 576px ) and (max-width: 1500px) {
            padding-left: 40px;
        }


    }
}

.preheadline {
    margin: 0;
    font: {
        size: rem(12px);
        family: "Montserrat", sans-serif;
        weight: 300;
    }
    letter-spacing: 0.2em;
    text-transform: uppercase;

    &--large {
        font-size: rem(18px);
    }
}
