.bg {
    @each $colorName, $color in $colors {
        &-#{$colorName} {
            background-color: $color;

            @if map-has-key($bgColorsWhiteFont, $colorName) {
                .content ul li:before,
                &.content ul li:before {
                    background-image: url(../images/list-white-arrow.svg);
                }
            }
        }

        &-transparent-#{$colorName} {
            background-color: rgba($color, 0.8);
        }
    }

    &-image {
        background-repeat: no-repeat; 
        background-size: contain; 
        background-position: center;

        &--cover {
            background-size: cover;
        }

        &--laptop-pos-top {

            @media all and (max-width: 991px) {
                background-position: top;
            }
        }

        &--container {
            position: relative;

            @media all and (min-width: 902px) {
                padding-top: 560px;
            }
        }

        &__inner {

            @media all and (min-width: 902px) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: 50%;
            }

            img {

                @media all and (min-width: 902px) {
                    display: none;
                }
            }
        }
    }

    &-video-popup {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 56.25%;
        background-size: cover;
        background-position: center;

        &:before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $colorBlack;
            opacity: 0.5;
            z-index: 1;
        }

        > svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            fill: white;
            z-index: 2;
        }
    }

    &-video {
        position: relative;

        &:before {
            content: "";
            display: block;
            padding-top: 56.25%;
        }

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
}