/**
*
* Variables
*
**/

$columnsWidthSet: (10, 20, 25, 30, 33, 40, 50, 60, 67, 70, 75, 80, 90);

/* Colors */

$colorMain: #ffffff;
$colorWhite: #ffffff;
$colorGold: #D58F31;
$colorGray: rgba(255, 255, 255, 0.5);
$colorDarkGray: #333333;
$colorRed:#FF4D4D;
$colorBlack: #000000;

$socialMediaColors: (
    'twitter': #4099ff,
    'linkedin': #007bb6,
    'facebook': #3b5998,
    'googleplus': #dd4b39,
    'email': #3bb55e,
    'instagram': #e1306c
);

$colors: (
    'white': $colorMain,
    'black': $colorBlack,
    'gold': $colorGold,
    'gray': $colorGray,
    'darkGray': $colorDarkGray,
    'red': $colorRed
);

$bgColorsWhiteFont: (
    'red': #d70000
);

$defaultFontSize: 18px;
$smallFontSize: 16px;
$largeFontSize: 24px;
