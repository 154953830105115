.response {
    margin: 1em 0;
    padding: 1em;
    text-align: center;

    p {
        @extend %Montserrat-light;
        margin: 0;
        padding: 0;
        font-size: 1em;

        &:first-child {
            text-transform: uppercase;
            letter-spacing: 0.2em;
        }
    }

    &--success {
        border: 1px solid $colorGold;

        p {
            &:first-child {
                color: $colorGold;
            }
        }
    }

    &--error {
        border: 1px solid $colorRed;

        p {
            &:first-child {
                color: $colorRed;
            }
        }
    }
}