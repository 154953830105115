.card {
    position: relative;
    display: block;
    background-position: center;
    color: #ffffff;

    &:not(.card--not-shrink) {
        &:before {
            content: "";
            position: relative;
            display: block;
            padding-top: 66.85%;
        }
    }

    &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $colorBlack;
    }

    &--wide {

        &:before {
            padding-top: 50%;
        }
    }


    &:hover:not(.card--not-shrink) {

        .card__img {
            transform: scale(0.65);

            @media all and (max-width: 1024px)  {
                transform: scale(0.85);
            }

            &:after {
                height: 100%;
            }

            &-inner {
                transform: scale(1.4);

                .box-text--link & {
                    width: 65%;
                    left: 17.5%;
                }
            }
        }

        .card__date {
            top: 83%;

            @media all and (max-width: 1024px) {
                top: 90%;
            }
        }

        .anim-on-hover {
            opacity: 1;
        }
    }

    &--portrait-img {

        &:before {
            padding-top: 150%;
        }
    }

    &.card--visible {

        &:not(.card--not-shrink) {
            .card__img {
                transform: scale(0.65);

                @media all and (max-width: 1024px)  {
                    transform: scale(0.85);
                }

                &:after {
                    height: 100%;
                }

                &-inner {
                    transform: scale(1.4);
                }
            }

            .card__date {
                top: 83%;

                @media all and (max-width: 1024px) {
                    top: 90%;
                }
            }

            .anim-on-hover {
                opacity: 1;
            }
        }
    }

    &-row {
        position: relative;

        &:before {
            content: "";
            width: calc( 92% / 12 * 8);
            height: calc( 100% - (((100vw - (100vw - 1550px))/ 12) * 5));
            display: block;
            position: absolute;
            top: calc(((100vw - (100vw - 1550px))/ 12) * 2);
            right: 50%;
            transform: translateX(50%);
            border: 1px solid #333333;

            @media all and (max-width: 1749px) {
                height: calc( 100% - (((100vw - (100vw - 92vw))/ 12) * 4));
                top: calc(((100vw - (100vw - 92vw))/ 12) * 1.5);
                width: calc( 92vw / 12 * 6);
            }
        }

        &--col {

            &-big {
                &:before {
                    width: calc( 92% / 12 * 7);
                    top: 50%;
                    transform: translate(50%, -50%);
                    height: calc(100%/2 + 120px);

                    @media all and (min-width: 1650px) and (max-width: 1749px) {
                        width: calc( 92% / 12 * 7);
                    }

                    @media all and (min-width: 1201px) and (max-width: 1649px) {
                        width: calc( 92% / 12 * 6);
                    }

                    @media all and (min-width: 641px) and (max-width: 1200px) {
                        width: calc( 92% / 12 * 6);
                        height: calc(100%/2 + 60px);
                    }

                    @media all and (max-width: 640px) {
                        width: calc( 92% / 12 * 6);
                        height: calc(100%/2 + 40px);
                    }
                }

                .card-col{
                    position: relative;

                    &:nth-child(odd) {
                        margin-bottom: 120px;

                        @media all and (min-width: 992px) and (max-width: 1200px) {
                            margin-bottom: 60px;
                        }

                        @media all and (max-width: 991px) {
                            margin-bottom: 20px;
                        }
                    }

                    &:nth-child(even) {
                        margin-top: 120px;

                        @media all and (min-width: 992px) and (max-width: 1200px) {
                            margin-top: 60px;
                        }

                        @media all and (max-width: 991px) {
                            margin-top: 20px;
                        }
                    }

                    &.card-col--to-left {

                        .card--portrait-img {

                            @media all and (min-width: 992px) {
                                left: -5%;
                            }
                        }
                    }

                    &.card-col--to-right {

                        .card--portrait-img {

                            @media all and (min-width: 992px) {
                                right: -5%;
                            }
                        }
                    }
                }

                .card--portrait-img {
                    width: 67%;
                    margin: 0 auto;

                    @media all and (max-width: 991px) {
                        width: 83%;
                    }
                }
            }

            &-small {

                &:before {
                    width: calc( 92% / 12 * 9);
                    top: 50%;
                    transform: translate(50%, -50%);
                    height: 65%;

                    @media all and (min-width: 641px) and (max-width: 1649px) {
                        width: calc( 92% / 12 * 8);
                    }
                }

                .card-col {

                    &:nth-child(odd) {

                        @media all and (max-width: 991px) {
                            margin-bottom: 40px;
                        }
                    }
                }

                .card--portrait-img {
                    width: 67%;
                    margin: 0 auto;

                    @media all and (max-width: 991px) {
                        width: 83%;
                    }
                }
            }
        }

        &-wrapper.section {
            // padding-top: 120px;
            // padding-bottom: 120px;

            @media all and (max-width: 991px) {
                padding-top: 20px;
                padding-bottom: 20px;
            }

            // &.section--p-xxlarge-top {
            //     @media all and (min-width: 992px) {
            //         padding-top: 220px;
            //     }
            // }
        }
    }

    &-col {

        &--to-left {

            @media all and (min-width: 1750px) {
                right: calc((100vw - (100vw - 1550px))/ 12);
            }
        }

        &--to-right {
            @media all and (min-width: 1750px) {
                left: calc((100vw - (100vw - 1550px))/ 12);
            }
        }

        &--to-bottom {
            margin-top: 18%;

            @media all and (min-width: 992px) and (max-width: 1750px) {
                margin-top: 14%;
            }

            @media all and (max-width: 991px) {
                margin-top:0;
            }
        }
    }

    &-outline {

        &:before {
            content: "";
            position: absolute;
            display: block;
            height: 67%;
            width: calc(100vw - (100vw - 1456px));
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            border: 1px solid #333333;

            @media all and (max-width: 1600px) {
                width: 92vw;
            }
        }

        &--narrow {
            &:before {
                width: 1056px;

                @media all and (max-width: 1600px) {
                    width: 73vw;
                }
            }
        }
    }


    &-wrapper {


        @media all and (min-width: 1750px) {
            margin-bottom: calc(((100vw - (100vw - 1550px))/ 12) * 1);
        }

        @media all and (max-width: 1749px) {
            margin-bottom: calc(((100vw - (100vw - 92vw))/ 12) * 1);
        }

        &:nth-child(odd) {

            @media all and (min-width: 1750px) {
                right: calc(((100vw - (100vw - 1550px))/ 12) * 0.5);
            }

            @media all and (min-width: 992px) and (max-width: 1749px) {
                left: calc(((100vw - (100vw - 92vw))/ 12) * 0.5);
            }
        }

        &:nth-child(even) {

            @media all and (min-width: 1750px) {
                left: calc(((100vw - (100vw - 1550px))/ 12) * 0.5);
            }

            @media all and (min-width: 992px) and (max-width: 1749px) {
                left: calc(((100vw - (100vw - 92vw))/ 12) * 1.5);
            }
        }
    }

    &__headline {
        position: absolute;
        top: 50%;
        left: 50%;
        margin: 0 -50% 0 0;
        transform: translate(-50%, -50%);
        justify-content: center;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        z-index: 10;
        pointer-events: none;
    }

    &__date {
        @extend %Montserrat-light;
        position: absolute;
        top: 75%;
        left: 17.5%;
        width: 65%;
        font-size: 14px;
        line-height: 22px;
        text-transform: uppercase;
        z-index: 1;
        transition: top 1s cubic-bezier(.3,0,.2,1);
        pointer-events: none;

        @media all and (min-width: 575px) and (max-width: 1024px)  {
            left: 7.5%;
            width: 85%;
        }

        @media all and (max-width: 574px) {
            left: 7.5%;
            width: 85%;
            font-size: 12px;
        }
    }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        will-change: transform;
        transition: transform 1s cubic-bezier(0.3, 0.00, 0.2, 1);
        z-index: 2;
        pointer-events: none;

        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: -10px;
            z-index: 10;
            width: calc(100% + 20px);
            height: 0;
            height: 0;
            background-color: rgba(0, 0, 0, 0.5);
            will-change: height;
            transform: translateZ(0);
            transition: height 1s cubic-bezier(0.3, 0.00, 0.2, 1);
        }

        &-inner {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            transition: transform 1s cubic-bezier(0.3, 0.00, 0.2, 1);

            .box-text--link & {
                left: 5px;
                width: calc(100% - 10px);
                transition: all 1s cubic-bezier(0.3, 0.00, 0.2, 1);
            }
        }

        img {
            visibility: hidden;
        }

        &-curtain {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background: $colorBlack;
            z-index: 2;
        }

    }

    .anim {

        &-on-hover {
            opacity: 0;
        }

        &-run {
            &1 {
                transition: all .6s ease-in-out .1s;
            }

            &2 {
                transition: all 1.2s ease-in-out .1s;
            }

            &3 {
                transition: all 1.8s ease-in-out .1s;
            }
        }
    }
}
