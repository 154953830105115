.box-img {
    position: relative;
    overflow: hidden;

    @media all and (min-width: 1750px) {
        right: calc( 1456px / 12);
    }

    @media all and (max-width: 991px) {
        margin-bottom: 20px;
    }

    &__item {
        position: relative;
        z-index: 1;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: left top;
        padding-bottom: 75%;
        transition: transform 1s cubic-bezier(0.3, 0.00, 0.2, 1);
        will-change: transform;
        transform: translateZ(0);

        img {
            display: none;
        }

        video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            visibility: visible;
            opacity: 1;
        }
    }

    &--to-right {
        @media all and (min-width: 1750px) {
            left: calc( 1456px / 12);
        }
    }

    img {
        visibility: hidden;
    }

    &__curtain {
        position: absolute;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background-color: $colorBlack;
        will-change: top;
    }
}
