/**
*
* Forms
*
**/

.form {
    @extend %Montserrat-light;
    position: relative;

    fieldset {
        @include clearfix();
    }

    label.error {
        position: absolute;
        top: 110%;
        left: 5%;

        font-size: rem(13px);
        color: $colorRed;
    }

    &__group {
        position:relative;
        margin-bottom: 0.6rem;

        @media all and (max-width: 567px) {
            margin-bottom: 1.6rem;
        }

        &--not-required {
            padding-bottom: 12px;
        }

        &--right {
            margin: {
                left: auto;
                right: 0;
            }
        }

        &--width {

            &-auto {
                flex: 0 1 auto!important;
                text-align: center;
            }

            &-half {
                flex: 0 1 50%!important;
            }
        }

        &.has-danger {

            .form__input,
            .form__textarea {
                border-color: $colorRed;

                &.filled:not(:focus) {
                    border-color: $colorRed;
                }
            }
        }
    }

    &__input,
    &__textarea {
        @extend %Montserrat-light;
        display: block;
        padding: 26px 0 12px 0;
        font-size: 18px;
        letter-spacing: 0.2em;
        width: 100%;
        color: $colorMain;
        border: 0;
        border-radius: 0;
        border-bottom: 1px solid $colorGray;
        transition: border 0.3s;
        background: transparent;

        &:focus {
            outline:none;
            border-color: $colorGold;
        }

        &:focus,
        &.filled {
            /* active state */
            ~ .form__label {
                transform: translateY(-24px);
                font-size: 14px;
                color: $colorMain;
            }
        }

        &.filled:not(:focus) {
            border-color: $colorMain;
        }

        &:disabled {
            opacity: 0.6;
        }

        &--no-border {
            border: 0;
        }

        &.error {
            border-color: $colorRed;
        }
    }

    &__label {
        position:absolute;
        left: 0;
        top: 24px;
        padding: 2px 0;
        display: inline-block;
        border-radius: 5px;
        color: $colorGray;
        font-size: 18px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        pointer-events:none;
        transition: 0.3s ease all;
        will-change: transform;
    }

    &__textarea {
        padding-top: 0;
        width: 100%;
        min-height: 140px;
        border-top: 26px solid transparent;
        border-top-color: transparent!important;
    }

    &__required {
        display: inline-block;
        padding-left: 24px;
        padding-top: 5px;
        font-size: 14px;
        color: $colorMain;
        line-height: 1.1;
    }

    &__error {
        position: absolute;
        padding-top: 8px;
        color: $colorRed;
        font-size: 14px;
    }

    .btn {
        background-color: transparent;
        text-transform: uppercase;
        outline: none;
    }


    &__group {
        position: relative;
        margin-top: 36px;
        margin-bottom: 0.6rem;

        @media all and (max-width: 567px) {
            margin-bottom: 20px;
        }

        &--not-required {
            padding-bottom: 12px;
        }

        &--right {
            margin: {
                left: auto;
                right: 0;
            }
        }

        &--width {

            &-auto {
                flex: 0 1 auto!important;
                text-align: center;
            }

            &-half {
                flex: 0 1 50%!important;
            }
        }

        &--no-mt {
            margin-top: 0;
        }

        &--file {
            display: block;

            > * {
                display: block;
                width: 100%;
                max-width: 100%;
            }

            // .form__input-descripton {
            //     display: block;
            //     margin-top: 4px;
            // }

            // .form__input {
            //     display: flex;
            //     justify-content: space-between;

            //     span {
            //         opacity: 0.5;
            //     }
            // }
        }
    }

    &--two-column {
        @media (min-width: 767px) {
            display: flex;
            flex-flow: row wrap;
            justify-content: space-between;
        }

        > * {
            &.form__group {
                @media (min-width: 767px) {
                    flex-grow: 1;
                    flex: 0 1 48%;
                    margin: 12px 0 24px 0;
                    max-width: 48%;
                }

                &--full-width {
                    flex: 1 1 100%;
                    max-width: 100%;

                    .checkbox__label {
                        padding: {
                            bottom: 4px;
                        };
                    }
                }

                &--30 {
                    @media (min-width: 767px) {
                        flex-grow: 1;
                        flex: 0 1 33%;
                        margin: 12px 0 24px 0;
                        max-width: 33%;

                        select {
                            min-width: 1px;
                        }
                    }
                }

                &--70 {
                    @media (min-width: 767px) {
                        flex-grow: 1;
                        flex: 0 1 63%;
                        margin: 12px 0 24px 0;
                        max-width: 63%;
                    }
                }
            }

            &:not(.form__group) {
                flex: 1 1 100%;
            }
        }
    }

    &__inline-choose {
        display: flex;
        width: 100%;
        flex-flow: row nowrap;
        align-items: stretch;

        input {
            opacity: 0;
            visibility: hidden;
            position: absolute;
            width: 1px;
            height: 1px;

            &:not(:checked) {
                + .btn {
                    &:before {
                        left: 0;
                        width: 100%;
                    }

                    &:after {
                        left: 0;
                        width: 100%;
                    }

                    .btn__inner {
                        &:before {
                            top: 0;
                            height: 100%;
                        }

                        &:after {
                            top: 0;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .btn {
            text-align: center;
        }
    }
}

// Checkbox & Radio inputs
.form__radio,
.form__checkbox {
    position: absolute;
    left: -999em;
}

.form__radio-label,
.form__checkbox-label {
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

.form__radio-label:before,
.form__checkbox-label:before {
    display: inline-block;
    position: relative;
    top: 2px;
    left: -2px;
    content:'';
    width: 25px;
    height: 23px;
    background: url(../images/formelements.png) top left no-repeat;
}

.form__checkbox + .form__checkbox-label:before {
    background-position: 0 -25px;
}

.form__checkbox:checked + .form__checkbox-label:before {
    background-position: 0 0 ;
}

.form__radio + .form__radio-label:before {
    background-position: -25px -25px;
}

.form__radio:checked + .form__radio-label:before {
    background-position: -25px 0;
}

// Select list
.form__select {
    overflow:hidden;
    padding:0;
    margin:0;
    max-width: 600px;

    select {
        width:115%;
        background-color:transparent;
        background-image:none;
        -webkit-appearance: none;
        border:none;
        box-shadow:none;
    }
}


.form__row {
    display: table;
    width: 100%;
    clear: both;

    @media (min-width: 568px) {
        padding: 10px 0;
    }

    &--flex {
        @media (min-width: 568px) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;

            > * {
                flex-basis: 0;
                flex-grow: 1;
                margin-right: 20px;

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    &__left,
    &__right {
        display: table-cell;
        vertical-align: middle;

        @media all and (min-width: 200px) and (max-width: 600px) {
            display: table;
        }
    }

    &__left {
        width: 48%;
        padding-right: 2%;

        @media all and (min-width: 200px) and (max-width: 600px) {
            width: 100%;
            padding-right: 0;
        }

        &--sm-50 {

            @media screen and (min-width: 992px) and (max-width: 1199px) {
                width: 48%;
                padding-right: 2%;
                display: table-cell;
                vertical-align: middle;
            }
        }
    }

    &__right {
        width: 48%;
        padding-left: 2%;

        @media all and (min-width: 200px) and (max-width: 600px) {
            width: 100%;
            padding-left: 0;
        }
        &--sm-50 {

            @media screen and (min-width: 992px) and (max-width: 1199px) {
                width: 48%;
                padding-right: 2%;
                display: table-cell;
                vertical-align: middle;
            }
        }

        &--text-right {
            text-align: right;

            @media all and (min-width: 200px) and (max-width: 600px) {
                text-align: left;
            }
        }
    }

    &--space-between {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media all and (max-width: 574px) {
            flex-direction: column;
        }

        > div {

            &:first-child {

                @media all and (max-width: 574px) {
                    margin-bottom: 10px;
                }
            }
        }
    }

    &--center {
        justify-content: center;
    }

    &--with-dropzone {
        .form__row__right,
        .form__row__left {
            vertical-align: top;
        }
    }
}



::-webkit-input-placeholder {
    color: rgba($colorMain, 0.5);
    font-size: 18px;
}

:-moz-placeholder {
    color: rgba($colorMain, 0.5);
    font-size: 18px;
}

::-moz-placeholder {
    color: rgba($colorMain, 0.5);
    font-size: 18px;
}

:-ms-input-placeholder {
    color: rgba($colorMain, 0.5);
    font-size: 18px;
}
