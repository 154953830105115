// body {
//     &.blurred  {
//         &::before {
//             content: '';
//             position: fixed;
//             width: 100%;
//             height: 100%;
//             pointer-events: none;
//             backdrop-filter: blur(28px);
//             z-index: 997;
//         }
//     }
// }

.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $colorBlack;
    filter: brightness(-50%);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;
    z-index: 998;

    &--light {
        background-color: rgba(255, 255, 255, 0.3);
    }

    &.show {
        opacity: 0.59;
        visibility: visible;
    }
}

.popup {
    $block-name: '.popup';

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    box-shadow: 0 10px 20px rgba(#000000, 0.15);
    background-color: $colorBlack;
    color: $colorWhite;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s;
    overflow: auto;
    z-index: 999;
    // width: 90%;

    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;

    // border: 1px solid $colorGray;

    &.show {
        opacity: 1;
        visibility: visible;
    }

    &__content-wrapper {
        // @include customScrollbar(4px);

        max-height: 90vh;
        // overflow: auto;

        @supports (-webkit-overflow-scrolling: touch) {
            height: calc(var(--vh, 1vh) * 90);
        }
    }

    &__close {
        position: absolute;
        top: 60px;
        right: 60px;
        width: 28px;
        height: 28px;

        display: block;
        padding: 0;
        background-color: transparent;
        cursor: pointer;
        transition: opacity 0.3s;
        z-index: 20;

        @include media('<=tablet') {
            position: fixed;
            top: 20px;
            right: 20px;
        }

        &:hover {
            opacity: 0.7;

            span {
                color: $colorGold;
            }
        }

        &:focus {
            outline: 0;
        }

        span {
            display: block;
            width: 36px;
            height: 1px;
            background-color: $colorWhite;
            transform: translateX(-5px) rotate(45deg);
            transition: color 0.3s;

            &:nth-child(2) {
                transform: translateX(-5px) rotate(-45deg);
            }
        }

        &-icon {
            fill: $colorWhite;

            @include media('>=tablet') {
                fill: $colorGold;
            }
        }
    }

    &--narrow {
        max-width: 850px;
    }

    &--notify-form {
        padding: 0;
        background-color: $colorGold;
        color: $colorBlack;
        border: 0;
        transform: initial;
        left: 0;
        top: 88px;

        @include media('>=tablet') {
            width: 50%;
            left: initial;
            right: 24px;
        }

        @include media('>=laptop') {
            top: 122px;
            right: 48px;
            width: 50%;
            max-width: 570px;
        }

        #{$block-name}__close {
            top: 36px;
            right: 36px;

            svg {
                fill: $colorWhite;
            }
        }
    }
}

.popup-inner {
    $block-name: '.popup-inner';

    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%, -50%);
    display: flex;
    padding: 32px;

    max-width: 1200px;
    border: 1px solid $colorGray;
    width: 100%;
    overflow: auto;

    @include media('>=tablet') {
        padding: 48px;
    }

    @include media('>=laptop') {
        padding: 64px;
        width: 1200px;
    }

    // &__button-wrapper {
    //     @include spreadChildren(24px, x);

    //     display: flex;
    //     justify-content: space-between;
    //     align-items: center;
    //     margin-top: 42px;

    //     @include media('>=tablet') {
    //         @include spreadChildren(48px, x);

    //         justify-content: flex-start;
    //     }

    //     @include media('>=laptop') {
    //         margin-top: 72px;
    //     }
    // }

    &--two-column {
        padding: 0;
        flex-direction: column;
        align-items: center;

        @include media('>=tablet') {
            flex-direction: row;
            padding: 48px;
        }

        #{$block-name}__column {
            @include media('>=tablet') {
                &:first-child {
                    flex: 1;
                    margin-right: 28px;
                }

                &:last-child {
                    flex: 2;
                }
            }

            @include media('>=laptop') {
                &:first-child {
                    flex: 1;
                    margin-right: 72px;
                }

                &:last-child {
                    flex: 1;
                }
            }
        }

        #{$block-name}__image {
            position: relative;
            padding-bottom: 0;

            @include media('>=tablet') {
                padding-bottom: 126%;
                border-radius: 0 0 48px 0;
            }

            .image {
                object-fit: cover;
                height: auto;

                @include media('>=tablet') {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }

        #{$block-name}__content {
            padding: 32px;

            @include media('>=tablet') {
                padding: 0;
            }
        }


        #{$block-name}__copy {
            margin-top: 24px;
        }
    }
}

.popup-form {
    padding: 36px;

    &__headline {
        margin: 0;
        text-transform: uppercase;
        font-size: 24px;
        font-weight: 900;

        @include media('>=laptop') {
            font-size: 32px;
        }
    }
}
