.letters {
    display: flex;
    flex-wrap: wrap;
    opacity: 0;

    &--processed {
        opacity: 1;
    }

    &.text-center {
        justify-content: center;
    }

    &.text-left {
        justify-content: flex-start;
    }
    
    &__word {
        margin-bottom: 0.3em;
        pointer-events: none;

        &:not(:last-child) {
            margin-right: 0.8em;
        }

        span:last-child {
            letter-spacing: 0;
        }

        &--last-char-white {
            span:last-child {
                color: white;;
            }
        }
    }
}