

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated1 {
  animation-duration: 0.6s;
}

.animated2 {
  animation-duration: 1.2s;
}

.animated3 {
  animation-duration: 1.8s;
}

.animated.infinite {
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

@-webkit-keyframes fadeInUpBig {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 2000px, 0);
    transform: translate3d(0, 2000px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}


@keyframes fadeInLetter {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeInLetter {
  -webkit-animation-name: fadeInLetter;
  animation-name: fadeInLetter;
  animation-timing-function: ease-in-out;
  animation-delay: 0.1s;
}

@keyframes slideInUp {
  from {
    height: 100%;
  }
  to {
    height: 0;
  }
}

@keyframes imageScale {
  from {
    transform: scale(1.1) 
  }
  to {
    transform: scale(1) 
  }
}

.animation-imageScale {
  &.lazy {

    + .slideInUp {
      top: 0;

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000 url(../images/loading.svg) center center no-repeat;
        opacity: 0;
        transition: opacity 0.2s 1.2s;
      }
    }

    &.animated {
      + .slideInUp {
        &:after {
          opacity: 1;
        }
      }
    }
  }
}

.imageScale {

  &.lazy {
    + .slideInUp {
      top: 0;

      &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: #000 url(../images/loading.svg) center center no-repeat;
        opacity: 0;
        transition: opacity 0.2s 1.2s;
      }
    }

    &.animated {
      + .slideInUp {
        &:after {
          opacity: 1;
        }
      }
    }

    &.bg-img-loaded {
      -webkit-animation-name: imageScale;
      animation-name: imageScale;
      animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
      animation-duration: 1.5s;

      + .slideInUp {
        -webkit-animation-name: slideInUp;
        animation-name: slideInUp;
        animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
        animation-duration: 1.25s;
        animation-fill-mode: both;

        &:after {
          display: none;
        }
      }
    }


  }

  &:not(.lazy) {

    + .slideInUp {
      top: 0;
    }

    -webkit-animation-name: imageScale;
    animation-name: imageScale;
    animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
    animation-duration: 1.5s;

    + .slideInUp {
      -webkit-animation-name: slideInUp;
      animation-name: slideInUp;
      animation-timing-function: cubic-bezier(0.5, 0, 0, 1);
      animation-duration: 1.25s;
      animation-fill-mode: both;
    }
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  animation-duration: 0.5s;

}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
  animation-duration: 0.5s;
}


.animation-bounceInLeft,
.animation-bounceInRight,
.animation-fadeInLetter,

.animation-fadeInUp {
    opacity: 0;
}

.animation-delay-1 {
  animation-delay: 200ms;
}

.animation-delay-2 {
  animation-delay: 400ms;
}

.animation-imageScale {
  &.lazy {
    + .animation-slideInUp {

    }
  }
}



@keyframes slideInUpBig {
  from {
    -webkit-transform: translate3d(0, 200px, 0);
    transform: translate3d(0, 200px, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slideInUpBig {
  -webkit-animation-name: slideInUpBig;
  animation-name: slideInUpBig;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
}


@keyframes slideOutUp {
  from {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    -webkit-transform: translate3d(0, -100vh, 0);
    transform: translate3d(0, -100vh, 0);
  }
}

.slideOutUp {
  -webkit-animation-name: slideOutUp;
  animation-name: slideOutUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

