.social {
    display: flex;
    justify-content: center;

    &__item {

        &:not(:last-child) {
            .social__link {
                &:after {
                    margin: 0 10px;
                    content: "/";
                    color: rgba(255, 255, 255, 0.5);
                }
            }
        }
    }

    &__link {
        @extend %Montserrat-light;
        position: relative;
        text-transform: uppercase;
        color: $colorGray;
        font-size: 13px;
        letter-spacing: 0.2em;
        transition: all 0.3s ease-in-out;

        &:hover {
            color: $colorMain;
        }

    }

    &--font-lg {
        transition: color cubic-bezier(0.3, 0.00, 0.2, 1);
        
        .social__link {
            font-size: 18px;
        }
    }
}