.checkbox {
    display: inline;
    opacity: 0;
    width: 0;
    margin: 0;
    overflow: hidden;
    -webkit-appearance: none;

    &__container {
        margin-bottom: 14px;

        &.checkbox__container_inline {
            display: inline-block;
        }


        &--box {
            height: auto;
            margin-bottom: 0;

            .checkbox__label {
                font-size: 16px;
                line-height: 1.3;

                /* IE Edge 12+ */
                @supports (-ms-accelerator:true) {
                    line-height: 1.5;
                }
            }
        }

        .checkbox {

            &--absolute {
                @media only screen and (min-device-width : 768px) and (max-device-width : 1040px) and (orientation : portrait) {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }
        }

        &--middle {
            .checkbox__label {
                &:before,
                &:after {
                    top: 50% !important;
                    transform: translateY(-50%);
                }
            }

            .checkbox:checked {
                + .checkbox__label {
                    &:after {
                        top: calc(50% - 6px) !important;
                    }
                }
            }
        }
    }

    & + &__inner-label,
    &__label {
        display: block;
        height: 100%;
        position: relative;
        padding: 6px 10px 15px 72px;
        cursor: pointer;
        line-height: 1.4;
        vertical-align: bottom;
        color: $colorMain;
        font: {
            size: 18px;
        }
        transition: color 200ms ease;

        &.checkbox__label_no-text {
            color: transparent;
            font-size: 0;
            width: 20px;
            padding-right: 0;
            padding-left: 20px;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            transition: transform 200ms ease, border-color 200ms ease, background-color 200ms ease;
        }

        &:before {
            width: 40px;
            height: 40px;
            background-color: transparent;
            border: 1px solid $colorWhite;
            border-radius: 0;
            left: 0;
            top: 10px;
        }

        &:after {
            border-color: #fff;
        }

        @media (hover: hover) {
            &:hover {
                &:before {
                    border: 1px solid $colorGray;
                }
            }
        }

        a {
            color: inherit;
            text-decoration: underline;
        }

        &--small {
            font-size: rem(16px);
            line-height: 1.25;
        }
    }

    & + &__inner-label {
        padding: 8px 8px 8px 28px;
        color: $colorGold;

        &::before {
            top: 8px;
            border-color: $colorGold;
        }

        &::after {
            top: 14px !important;
        }

    }

    &:checked {

        & + .checkbox__inner-label,
        & + .checkbox__label {

            &:before,
            &:hover:before {
                border-color: $colorGold;
                background-color: transparent;
            }

            &:after {
                top: 18px;
                left: 8px;
                width: 26px;
                height: 16px;
                border: 3px solid $colorGold;
                border-top-style: none;
                border-right-style: none;
                transform: rotate(-45deg);

                .form--dark-bg & {
                    border-color: $colorGold;
                }
            }
        }


        @each $colorName, $color in $colors {
            .checkbox__container--box-#{$colorName} & {
                + .checkbox__label:before {
                    border-color: $color;
                    background-color: $color;
                }

            }
        }
    }

}
