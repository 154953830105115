.burger {
    background-color: transparent;
    outline: none;
    cursor: pointer;

    &-item {
        display: block;
        width: 40px;
        height: 1px;
        background-color: $colorMain;
        transition: all 0.3s ease-out;

        @media all and (max-width: 991px) {
            width: 16px;
        }

        &:nth-of-type(2) {
            margin-top: 20px;

            @media all and (max-width: 991px) {
                margin-top: 8px;
            }
        }
    }

    &--open {

        .burger-item--first {
            transform: translateY(10px) rotate(-45deg); 
            
            @media all and (max-width: 991px) {
                transform: translateY(5px) rotate(-45deg); 
            }
        }

        .burger-item--second {
            transform: translateY(-10px) rotate(45deg); 

            @media all and (max-width: 991px) {
                transform: translateY(-4px) rotate(45deg); 
            }
        }
    }
}