.l-menu {

    &-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        margin-top: 148px;
        width: 100%;
        height: calc(100vh - 148px );
        background-color: $colorBlack;
        z-index: 100;
        transition: all 1s cubic-bezier(0.3, 0, 0.2, 1);

        @media all and (max-width: 991px) {
            margin-top: 50px;
            padding-bottom: 100px;
            min-height: calc( 100vh - 50px );
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
            overflow-y: scroll;
            overflow-x: hidden;
        }

        &--scrollY {
            overflow-y: scroll;
            padding-bottom: 100px;
        }

        &--mobile {
            @media all and (max-width: 991px) {
                margin-top: 50px;
            }

            .l-menu__navbar {

                @media all and (max-width: 991px) {
                    display: flex;
                }
            }
        }

        &.hide {
            opacity: 0;
            z-index: -1;
            visibility: hidden;

            .l-menu-right {

                @media all and (min-width: 1550px) {
                    opacity: 0;
                    right: calc((-100vw + 1486px)/2);
                }

                @media all and (min-width: 992px) and (max-width: 1549px) {
                    opacity: 0;
                    right: -20%;
                }


                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    right: -400px;
                }
            }
        }

        .animation-fadeInLetter {
            opacity: 1;
        }
    }


    &-left {
        padding: 85px 0;

        @media all and (min-width: 992px) {
            border: 1px solid $colorDarkGray;
        }

        @media all and (max-width: 991px) {
            padding: 15px 40px!important;
        }

        &:after {
            @media all and (max-width: 991px) {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: -2%;
                width: 100vw;
                height: 1px;
                background-color: $colorDarkGray;
            }
        }

        &__link {
            @include dynamicFontSize(24, 32, 480, 1600);
            position: relative;
            padding: 35px 0;
            display: inline-block;
            line-height: 1.1;
            font-family: "Butler", serif;
            font-weight: 400;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            color: $colorMain;

            @media all and (max-width: 991px) {
                padding: 25px 0;
            }

            &:before {
                content: "";
                display: inline-block;
                position: absolute;
                left: 0;
                bottom: 20px;
                width: 100%;
                border-bottom: 1px solid $colorGold;
                pointer-events: none;
            }

            &:after {
                content: "";
                display: inline-block;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                bottom: 20px;
                width: 0%;
                transition: width 1s cubic-bezier(0.3, 0, 0.2, 1);
                border-bottom: 1px solid $colorMain;
                pointer-events: none;
            }

            &:hover {

                &:after {
                    width: 100%;
                }
            }
        }
    }

    &-right {
        padding: 40px;
        width: 100%;
        text-align: center;
        background-color: $colorBlack;
        font-weight: 300;

        @media all and (min-width: 1550px) {
            position: absolute;
            right: 0;
            width: 473px;
            top: 50%;
            transform: translateY(-50%);
            border: 1px solid $colorDarkGray;
            opacity: 1;
            transition: all 0.8s ease-out 0.5s;
        }

        @media all and (min-width: 992px) and (max-width: 1549px) {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            width: 473px;
            border: 1px solid $colorDarkGray;
            opacity: 1;
            transition: all 0.8s ease-out 0.5s;
        }

        a {
            letter-spacing: 0.2em;
            color: $colorMain;
        }
    }

    &__navbar {

        justify-content: center;
        align-items: center;
        opacity: 0;
        z-index: -10;
        transition: all 0.5s ease-out;

        @media all and (max-width: 991px) {
            padding: {
                top: 10px;
                bottom: 10px;
            }
        }

        &-logo {
            width: 40px;
            height: 29px;
        }

        .burger {
            position: absolute;
            right: 25px;
        }

        &--show {

            @media all and (max-width: 991px) {
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                display: flex;
                background-color: $colorBlack;
                opacity: 1;
                z-index: 10;
                border-bottom: 1px solid $colorDarkGray;
            }
        }
    }
}
