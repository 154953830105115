/**
*
* Blockquote
*
**/

.blockquote {
    margin: 0;
    padding: 100px 140px;
    text-align: center;

    @media all and (min-width: 481px) and (max-width: 901px) {
        padding: 50px 80px;
    }

    @media all and (max-width: 480px) {
        padding: 40px 60px;
    }
 
    &-wrapper {
        position: relative;
        margin-right: auto;
        margin-left: auto;
        padding: 100px 0;
    
        width: 92%;
        max-width: 1000px;
    }

    > p {
        @extend %Montserrat-light;
        @include dynamicFontSize(18, 24, 480, 1600);
        margin: 0;
        position: relative;


        &:first-child:before,
        &:first-child:after {
            position: absolute;
            font-size: 1.4em;
            color: #fff;
            width: 18px;
            height: 18px;

            @media all and (max-width: 480px) {
                width: 16px;
                height: 16px;
            }
        }

       &:first-child:before {
            content: '';
            position: absolute;
            top: 0;
            left: -40px;
            background: url(../images/icons/left-quotes-sign.svg) no-repeat center center/ 100% auto;

            @media all and (max-width: 480px) {
                left: -30px;
            }
        }

        &:last-of-type:after {
            content: '';
            position: absolute;
            top: 0;
            right: -40px;
            background: url(../images/icons/right-quotes-symbol.svg) no-repeat center center/ 100% auto;

            @media all and (max-width: 480px) {
                right: -30px;
            }
        }

        &:last-of-type {
            margin-bottom: .5em;
        }
    }

    &__name {
        @include dynamicFontSize(18, 24, 480, 1600);
        margin: 40px 0 10px;
        font-family: 'Butler';
        text-transform: uppercase;
        letter-spacing: 0.2em;
    }

    &__position {
        @extend %Montserrat-light;
        @include dynamicFontSize(16, 18, 480, 1600);
        margin: 0;
        letter-spacing: 0.2em;
        color: $colorGold;
    }
}