
.border {
    border: 1px solid #333333;

    &--edge-left,
    &--edge-right {
        position: absolute;
        top: 0;
        bottom: 0;
        width: calc((100vw - 1456px)/2);

        @media all and (max-width: 1600px) {
            width: calc((100vw - 1200px)/2);
        }

        @media all and (max-width: 1200px) {
            display: none;
        }
    }

    &--edge-left {
        left: 0;
    }

    &--edge-right {
        right: 0;
    }


    &-wrapper {
        position: relative;
    }
}
