
.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
    z-index: -1;

    + label {
        width: 100%;
        text-overflow: ellipsis;
        overflow: hidden;
        cursor: pointer;

        * {
            pointer-events: none;
        }
    }

}
