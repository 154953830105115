/* The container must be positioned relative: */
.custom-select {
    position: relative;
    font-family: Arial;

    &--width-sm {
        margin: 40px auto 0;

        @media all and (min-width: 575px) {
            width: 473px;
        }
    }

    select {
        display: none; /*hide original SELECT element: */
    }
        
    .select {
        &-selected {
            @extend %Montserrat-light;
            position: relative;
            padding: 20px;
            color: #ffffff;
            border: 1px solid transparent;
            border-color: $colorDarkGray;
            cursor: pointer;
            background-color: $colorBlack;
            text-transform: uppercase;
            font-size: 18px;
            letter-spacing: 0.2em;
            text-align: center;
            z-index: 99;


            &__icon {
                margin-left: 15px;
            }

            &:before,
            &:after {
                content:"";
                width: 0;
                height: 1px;
                position: absolute;
                transition: all cubic-bezier(0.3, 0.00, 0.2, 1);
                background: $colorGold;
                box-sizing: border-box;
            }
        
            &:before{
                left: 50%;
                top: 0;
                transition-duration: 1s;
            }
        
            &:after {
                left: 50%;
                bottom: 0;
                transition-duration: 1s;
            }
        
            &:hover {
                cursor: pointer;
        
                &:before {
                    left: 0;
                    width: 100%;
                }
        
                &:after {
                    left: 0;
                    width: 100%;
                }

                .select-selected__inner {
                    &:before {
                        top: 0;
                        height: 100%;
                    }
            
                    &:after {
                        top: 0;
                        height: 100%;
                    }
                }
            }

            &__inner {

                &:before,
                &:after {
                    content:"";
                    width: 1px;
                    height: 0;
                    position: absolute;
                    transition: all cubic-bezier(0.3, 0.00, 0.2, 1);
                    background: $colorGold;
                    box-sizing: border-box;
                }
        
                &:before{
                    left: 0;
                    top: 50%;
                    transition-duration: 1s;
                }
            
                &:after {
                    right: 0;
                    top: 50%;
                    transition-duration: 1s;
                }
            }
        }
    }

    .select-items  {
        @extend %Montserrat-light;

        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 0.2em;
        text-align: center;

        &__element {
            position: absolute;
            width: 100%;
            display: block;
            color: #ffffff;
            padding: 20px;
            border: 1px solid transparent;
            border-color: $colorDarkGray;
            background-color: $colorBlack;
            cursor: pointer;
            visibility: hidden;
            top: 0;
            z-index: 1;
            transition: all 0.5s ease-out;

            &:before,
            &:after {
                content:"";
                width: 0;
                height: 1px;
                position: absolute;
                transition: all cubic-bezier(0.3, 0.00, 0.2, 1);
                background: $colorGold;
                box-sizing: border-box;
            }
        
            &:before{
                left: 50%;
                top: 0;
                transition-duration: 1s;
            }
        
            &:after {
                left: 50%;
                bottom: 0;
                transition-duration: 1s;
            }
        
            &:hover {
                cursor: pointer;
        
                &:before {
                    left: 0;
                    width: 100%;
                }
        
                &:after {
                    left: 0;
                    width: 100%;
                }

                .select-items__element-inner {
                    &:before {
                        top: 0;
                        height: 100%;
                    }
            
                    &:after {
                        top: 0;
                        height: 100%;
                    }
                }
            }

            &-inner {
                pointer-events: none;

                &:before,
                &:after {
                    content:"";
                    width: 1px;
                    height: 0;
                    position: absolute;
                    transition: all cubic-bezier(0.3, 0.00, 0.2, 1);
                    background: $colorGold;
                    box-sizing: border-box;
                }
        
                &:before{
                    left: 0;
                    top: 50%;
                    transition-duration: 1s;
                }
            
                &:after {
                    right: 0;
                    top: 50%;
                    transition-duration: 1s;
                }
            }
        }
    }
}
