/**
*
* Pagination
*
**/

.pagination {
    clear: both;
    padding: 1em 0;
    text-align: center;

    li,
    a {
        display: inline-block;
    }

    a {
        padding: .3em 1em;
        border-radius: 5px;
        background: #222;
        color: #fff;

        &.active,
        &:hover {
            background: #000;
        }
    }
}