.box {

    &--bordered {
        border: 1px solid $colorGold;
    }

    &--flex {

        @media all and (min-width: 991px) {
            display: flex;
            justify-content: center;
        }
    }

    &__item {
        padding: 20px;
        flex-basis: calc((100vw - (100vw - 1456px))/12 * 3);
    }

    &--padded {
        padding: 40px;

        @media all and (max-width: 640px) {
            padding: {
                left: 20px;
                right: 20px;
            }
        }
    }

    &-wrapper {
        margin-bottom: 30px;
    }

    &--height-full {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
}