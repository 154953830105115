/**
*
* Grid (Bootstrap 4.0 grid)
*
**/

$gutters: (20, 30, 40, 50);

  .row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;

    &__full-width-child {
      flex-basis: 100%;
    }

    @each $gutter in $gutters {
      &--g-#{$gutter} {
          margin: {
              left: -$gutter * 1px;
              right: -$gutter * 1px;
          }

          > [class*="col-"] {
              padding: {
                left: $gutter * 1px;
                right: $gutter * 1px;
                bottom: $gutter * 2px;
              }
          }
      }
    }
  }

  .one-col-indent {
    margin-left: calc(1456px / 12);

    @media (max-width: 1500px) {
      margin-left: calc(100% / 12);
    }
  }
  
  .no-gutters {
    margin-right: 0;
    margin-left: 0;
  }
  
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  
  .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
  .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
  .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
  .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
  .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
  .col-xl-auto, .col-five, .col-sm-five, .col-md-five, .col-lg-five, .col-xl-five {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
  }
  
  .col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  
  .col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  
  .col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  
  .col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  
  .col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  
  .col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  
  .col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  
  .col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  
  .col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  
  .col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  
  .col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  
  .col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  
  .col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .col-five {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  
  .order-first {
    -ms-flex-order: -1;
    order: -1;
  }
  
  .order-last {
    -ms-flex-order: 13;
    order: 13;
  }
  
  .order-0 {
    -ms-flex-order: 0;
    order: 0;
  }
  
  .order-1 {
    -ms-flex-order: 1;
    order: 1;
  }
  
  .order-2 {
    -ms-flex-order: 2;
    order: 2;
  }
  
  .order-3 {
    -ms-flex-order: 3;
    order: 3;
  }
  
  .order-4 {
    -ms-flex-order: 4;
    order: 4;
  }
  
  .order-5 {
    -ms-flex-order: 5;
    order: 5;
  }
  
  .order-6 {
    -ms-flex-order: 6;
    order: 6;
  }
  
  .order-7 {
    -ms-flex-order: 7;
    order: 7;
  }
  
  .order-8 {
    -ms-flex-order: 8;
    order: 8;
  }
  
  .order-9 {
    -ms-flex-order: 9;
    order: 9;
  }
  
  .order-10 {
    -ms-flex-order: 10;
    order: 10;
  }
  
  .order-11 {
    -ms-flex-order: 11;
    order: 11;
  }
  
  .order-12 {
    -ms-flex-order: 12;
    order: 12;
  }
  
  .offset-1 {
    margin-left: 8.333333%;
  }
  
  .offset-2 {
    margin-left: 16.666667%;
  }
  
  .offset-3 {
    margin-left: 25%;
  }
  
  .offset-4 {
    margin-left: 33.333333%;
  }
  
  .offset-5 {
    margin-left: 41.666667%;
  }
  
  .offset-6 {
    margin-left: 50%;
  }
  
  .offset-7 {
    margin-left: 58.333333%;
  }
  
  .offset-8 {
    margin-left: 66.666667%;
  }
  
  .offset-9 {
    margin-left: 75%;
  }
  
  .offset-10 {
    margin-left: 83.333333%;
  }
  
  .offset-11 {
    margin-left: 91.666667%;
  }
  
  .offset-five {
    margin-left: 20%;
  }

  .offset-pad-1 {
    padding-left: 8.333333%;
  }
  
  .offset-pad-2 {
    padding-left: 16.666667%;
  }
  
  .offset-pad-3 {
    padding-left: 25%;
  }
  
  .offset-pad-4 {
    padding-left: 33.333333%;
  }
  
  .offset-pad-5 {
    padding-left: 41.666667%;
  }
  
  .offset-pad-6 {
    padding-left: 50%;
  }
  
  .offset-pad-7 {
    padding-left: 58.333333%;
  }
  
  .offset-pad-8 {
    padding-left: 66.666667%;
  }
  
  .offset-pad-9 {
    padding-left: 75%;
  }
  
  .offset-pad-10 {
    padding-left: 83.333333%;
  }
  
  .offset-pad-11 {
    padding-left: 91.666667%;
  }
  
  .offset-pad-five {
    padding-left: 20%;
  }

  @media (min-width: 576px) {
    .col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-sm-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-sm-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-sm-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-sm-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-sm-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-sm-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-sm-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-sm-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-sm-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-sm-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-sm-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-sm-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-sm-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-sm-five {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    .order-sm-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-sm-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-sm-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-sm-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-sm-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-sm-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-sm-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-sm-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-sm-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-sm-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-sm-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-sm-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-sm-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-sm-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-sm-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-sm-0 {
      margin-left: 0;
    }
    .offset-sm-1 {
      margin-left: 8.333333%;
    }
    .offset-sm-2 {
      margin-left: 16.666667%;
    }
    .offset-sm-3 {
      margin-left: 25%;
    }
    .offset-sm-4 {
      margin-left: 33.333333%;
    }
    .offset-sm-5 {
      margin-left: 41.666667%;
    }
    .offset-sm-6 {
      margin-left: 50%;
    }
    .offset-sm-7 {
      margin-left: 58.333333%;
    }
    .offset-sm-8 {
      margin-left: 66.666667%;
    }
    .offset-sm-9 {
      margin-left: 75%;
    }
    .offset-sm-10 {
      margin-left: 83.333333%;
    }
    .offset-sm-11 {
      margin-left: 91.666667%;
    }
    .offset-sm-five {
      margin-left: 20%;
    }
    .offset-pad-sm-0 {
      padding-left: 0;
    }
    .offset-pad-sm-1 {
      padding-left: 8.333333%;
    }
    .offset-pad-sm-2 {
      padding-left: 16.666667%;
    }
    .offset-pad-sm-3 {
      padding-left: 25%;
    }
    .offset-pad-sm-4 {
      padding-left: 33.333333%;
    }
    .offset-pad-sm-5 {
      padding-left: 41.666667%;
    }
    .offset-pad-sm-6 {
      padding-left: 50%;
    }
    .offset-pad-sm-7 {
      padding-left: 58.333333%;
    }
    .offset-pad-sm-8 {
      padding-left: 66.666667%;
    }
    .offset-pad-sm-9 {
      padding-left: 75%;
    }
    .offset-pad-sm-10 {
      padding-left: 83.333333%;
    }
    .offset-pad-sm-11 {
      padding-left: 91.666667%;
    }
    .offset-pad-sm-five {
      padding-left: 20%;
    }
  }
  
  @media (min-width: 768px) {
    .col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-md-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-md-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-md-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-md-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-md-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-md-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-md-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-md-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-md-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-md-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-md-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-md-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-md-five {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    .order-md-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-md-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-md-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-md-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-md-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-md-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-md-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-md-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-md-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-md-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-md-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-md-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-md-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-md-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-md-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-md-0 {
      margin-left: 0;
    }
    .offset-md-1 {
      margin-left: 8.333333%;
    }
    .offset-md-2 {
      margin-left: 16.666667%;
    }
    .offset-md-3 {
      margin-left: 25%;
    }
    .offset-md-4 {
      margin-left: 33.333333%;
    }
    .offset-md-5 {
      margin-left: 41.666667%;
    }
    .offset-md-6 {
      margin-left: 50%;
    }
    .offset-md-7 {
      margin-left: 58.333333%;
    }
    .offset-md-8 {
      margin-left: 66.666667%;
    }
    .offset-md-9 {
      margin-left: 75%;
    }
    .offset-md-10 {
      margin-left: 83.333333%;
    }
    .offset-md-11 {
      margin-left: 91.666667%;
    }
    .offset-md-five {
      margin-left: 20%;
    }

    .offset-pad-md-0 {
      padding-left: 0;
    }
    .offset-pad-md-1 {
      padding-left: 8.333333%;
    }
    .offset-pad-md-2 {
      padding-left: 16.666667%;
    }
    .offset-pad-md-3 {
      padding-left: 25%;
    }
    .offset-pad-md-4 {
      padding-left: 33.333333%;
    }
    .offset-pad-md-5 {
      padding-left: 41.666667%;
    }
    .offset-pad-md-6 {
      padding-left: 50%;
    }
    .offset-pad-md-7 {
      padding-left: 58.333333%;
    }
    .offset-pad-md-8 {
      padding-left: 66.666667%;
    }
    .offset-pad-md-9 {
      padding-left: 75%;
    }
    .offset-pad-md-10 {
      padding-left: 83.333333%;
    }
    .offset-pad-md-11 {
      padding-left: 91.666667%;
    }
    .offset-pad-md-five {
      padding-left: 20%;
    }
  }
  
  @media (min-width: 992px) {
    .col-lg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-lg-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-lg-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-lg-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-lg-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-lg-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-lg-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-lg-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-lg-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-lg-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-lg-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-lg-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-lg-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-lg-five {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    .order-lg-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-lg-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-lg-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-lg-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-lg-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-lg-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-lg-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-lg-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-lg-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-lg-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-lg-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-lg-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-lg-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-lg-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-lg-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-lg-0 {
      margin-left: 0;
    }
    .offset-lg-1 {
      margin-left: 8.333333%;
    }
    .offset-lg-2 {
      margin-left: 16.666667%;
    }
    .offset-lg-3 {
      margin-left: 25%;
    }
    .offset-lg-4 {
      margin-left: 33.333333%;
    }
    .offset-lg-5 {
      margin-left: 41.666667%;
    }
    .offset-lg-6 {
      margin-left: 50%;
    }
    .offset-lg-7 {
      margin-left: 58.333333%;
    }
    .offset-lg-8 {
      margin-left: 66.666667%;
    }
    .offset-lg-9 {
      margin-left: 75%;
    }
    .offset-lg-10 {
      margin-left: 83.333333%;
    }
    .offset-lg-11 {
      margin-left: 91.666667%;
    }
    .offset-lg-five {
      margin-left: 20%;
    }

    .offset-pad-lg-0 {
      padding-left: 0;
    }
    .offset-pad-lg-1 {
      padding-left: 8.333333%;
    }
    .offset-pad-lg-2 {
      padding-left: 16.666667%;
    }
    .offset-pad-lg-3 {
      padding-left: 25%;
    }
    .offset-pad-lg-4 {
      padding-left: 33.333333%;
    }
    .offset-pad-lg-5 {
      padding-left: 41.666667%;
    }
    .offset-pad-lg-6 {
      padding-left: 50%;
    }
    .offset-pad-lg-7 {
      padding-left: 58.333333%;
    }
    .offset-pad-lg-8 {
      padding-left: 66.666667%;
    }
    .offset-pad-lg-9 {
      padding-left: 75%;
    }
    .offset-pad-lg-10 {
      padding-left: 83.333333%;
    }
    .offset-pad-lg-11 {
      padding-left: 91.666667%;
    }
    .offset-pad-lg-five {
      padding-left: 20%;
    }
  }
  
  @media (min-width: 1200px) {
    .col-xl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-xl-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-xl-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-xl-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-xl-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-xl-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-xl-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xl-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-xl-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-xl-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xl-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-xl-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-xl-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-xl-five {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    .order-xl-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-xl-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-xl-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-xl-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-xl-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-xl-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-xl-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-xl-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-xl-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-xl-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-xl-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-xl-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-xl-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-xl-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-xl-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-xl-0 {
      margin-left: 0;
    }
    .offset-xl-1 {
      margin-left: 8.333333%;
    }
    .offset-xl-2 {
      margin-left: 16.666667%;
    }
    .offset-xl-3 {
      margin-left: 25%;
    }
    .offset-xl-4 {
      margin-left: 33.333333%;
    }
    .offset-xl-5 {
      margin-left: 41.666667%;
    }
    .offset-xl-6 {
      margin-left: 50%;
    }
    .offset-xl-7 {
      margin-left: 58.333333%;
    }
    .offset-xl-8 {
      margin-left: 66.666667%;
    }
    .offset-xl-9 {
      margin-left: 75%;
    }
    .offset-xl-10 {
      margin-left: 83.333333%;
    }
    .offset-xl-11 {
      margin-left: 91.666667%;
    }
    .offset-xl-five {
      margin-left: 20%;
    }
  }

  @media (min-width: 1750px) {
    .col-xxl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .col-xxl-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .col-xxl-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .col-xxl-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .col-xxl-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .col-xl-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .col-xxl-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .col-xxl-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .col-xxl-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .col-xxl-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .col-xxl-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .col-xxl-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .col-xxl-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .col-xxl-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .col-xxl-five {
      -ms-flex: 0 0 20%;
      flex: 0 0 20%;
      max-width: 20%;
    }
    .order-xxl-first {
      -ms-flex-order: -1;
      order: -1;
    }
    .order-xxl-last {
      -ms-flex-order: 13;
      order: 13;
    }
    .order-xxl-0 {
      -ms-flex-order: 0;
      order: 0;
    }
    .order-xxl-1 {
      -ms-flex-order: 1;
      order: 1;
    }
    .order-xxl-2 {
      -ms-flex-order: 2;
      order: 2;
    }
    .order-xxl-3 {
      -ms-flex-order: 3;
      order: 3;
    }
    .order-xxl-4 {
      -ms-flex-order: 4;
      order: 4;
    }
    .order-xxl-5 {
      -ms-flex-order: 5;
      order: 5;
    }
    .order-xxl-6 {
      -ms-flex-order: 6;
      order: 6;
    }
    .order-xxl-7 {
      -ms-flex-order: 7;
      order: 7;
    }
    .order-xxl-8 {
      -ms-flex-order: 8;
      order: 8;
    }
    .order-xxl-9 {
      -ms-flex-order: 9;
      order: 9;
    }
    .order-xxl-10 {
      -ms-flex-order: 10;
      order: 10;
    }
    .order-xxl-11 {
      -ms-flex-order: 11;
      order: 11;
    }
    .order-xxl-12 {
      -ms-flex-order: 12;
      order: 12;
    }
    .offset-xxl-0 {
      margin-left: 0;
    }
    .offset-xxl-1 {
      margin-left: 8.333333%;
    }
    .offset-xxl-2 {
      margin-left: 16.666667%;
    }
    .offset-xxl-3 {
      margin-left: 25%;
    }
    .offset-xxl-4 {
      margin-left: 33.333333%;
    }
    .offset-xxl-5 {
      margin-left: 41.666667%;
    }
    .offset-xxl-6 {
      margin-left: 50%;
    }
    .offset-xxl-7 {
      margin-left: 58.333333%;
    }
    .offset-xxl-8 {
      margin-left: 66.666667%;
    }
    .offset-xxl-9 {
      margin-left: 75%;
    }
    .offset-xxl-10 {
      margin-left: 83.333333%;
    }
    .offset-xxl-11 {
      margin-left: 91.666667%;
    }
    .offset-xxl-five {
      margin-left: 20%;
    }
  }
  
  .d-none {
    display: none !important;
  }
  
  .d-inline {
    display: inline !important;
  }
  
  .d-inline-block {
    display: inline-block !important;
  }
  
  .d-block {
    display: block !important;
  }
  
  .d-table {
    display: table !important;
  }
  
  .d-table-row {
    display: table-row !important;
  }
  
  .d-table-cell {
    display: table-cell !important;
  }
  
  .d-flex {
    display: -ms-flexbox !important;
    display: flex !important;
  }
  
  .d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }
  
  @media (min-width: 576px) {
    .d-sm-none {
      display: none !important;
    }
    .d-sm-inline {
      display: inline !important;
    }
    .d-sm-inline-block {
      display: inline-block !important;
    }
    .d-sm-block {
      display: block !important;
    }
    .d-sm-table {
      display: table !important;
    }
    .d-sm-table-row {
      display: table-row !important;
    }
    .d-sm-table-cell {
      display: table-cell !important;
    }
    .d-sm-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-sm-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 768px) {
    .d-md-none {
      display: none !important;
    }
    .d-md-inline {
      display: inline !important;
    }
    .d-md-inline-block {
      display: inline-block !important;
    }
    .d-md-block {
      display: block !important;
    }
    .d-md-table {
      display: table !important;
    }
    .d-md-table-row {
      display: table-row !important;
    }
    .d-md-table-cell {
      display: table-cell !important;
    }
    .d-md-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-md-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 992px) {
    .d-lg-none {
      display: none !important;
    }
    .d-lg-inline {
      display: inline !important;
    }
    .d-lg-inline-block {
      display: inline-block !important;
    }
    .d-lg-block {
      display: block !important;
    }
    .d-lg-table {
      display: table !important;
    }
    .d-lg-table-row {
      display: table-row !important;
    }
    .d-lg-table-cell {
      display: table-cell !important;
    }
    .d-lg-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-lg-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media (min-width: 1200px) {
    .d-xl-none {
      display: none !important;
    }
    .d-xl-inline {
      display: inline !important;
    }
    .d-xl-inline-block {
      display: inline-block !important;
    }
    .d-xl-block {
      display: block !important;
    }
    .d-xl-table {
      display: table !important;
    }
    .d-xl-table-row {
      display: table-row !important;
    }
    .d-xl-table-cell {
      display: table-cell !important;
    }
    .d-xl-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-xl-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }

  @media (min-width: 1500px) {
    .d-xxl-none {
      display: none !important;
    }
    .d-xxl-inline {
      display: inline !important;
    }
    .d-xxl-inline-block {
      display: inline-block !important;
    }
    .d-xxl-block {
      display: block !important;
    }
    .d-xxl-table {
      display: table !important;
    }
    .d-xxl-table-row {
      display: table-row !important;
    }
    .d-xxl-table-cell {
      display: table-cell !important;
    }
    .d-xxl-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-xxl-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  @media print {
    .d-print-none {
      display: none !important;
    }
    .d-print-inline {
      display: inline !important;
    }
    .d-print-inline-block {
      display: inline-block !important;
    }
    .d-print-block {
      display: block !important;
    }
    .d-print-table {
      display: table !important;
    }
    .d-print-table-row {
      display: table-row !important;
    }
    .d-print-table-cell {
      display: table-cell !important;
    }
    .d-print-flex {
      display: -ms-flexbox !important;
      display: flex !important;
    }
    .d-print-inline-flex {
      display: -ms-inline-flexbox !important;
      display: inline-flex !important;
    }
  }
  
  .flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important;
  }
  
  .flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important;
  }
  
  .flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important;
  }
  
  .flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important;
  }
  
  .flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important;
  }
  
  .flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  
  .flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important;
  }
  
  .flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important;
  }
  
  .flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
  }
  
  .flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }
  
  .flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important;
  }
  
  .flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important;
  }
  
  .justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  
  .justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  
  .justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  
  .justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  
  .justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  
  .align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  
  .align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  
  .align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  
  .align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important;
  }
  
  .align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important;
  }
  
  .align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important;
  }
  
  .align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important;
  }
  
  .align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important;
  }
  
  .align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important;
  }
  
  .align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important;
  }
  
  .align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important;
  }
  
  .align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important;
  }
  
  .align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important;
  }
  
  .align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important;
  }
  
  .align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important;
  }
  
  .align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important;
  }
  
  .align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important;
  }
  
  @media (min-width: 576px) {
    .flex-sm-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-sm-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-sm-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-sm-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-sm-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-sm-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-sm-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-sm-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-sm-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-sm-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-sm-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-sm-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-sm-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-sm-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-sm-center {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-sm-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-sm-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-sm-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-sm-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-sm-center {
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-sm-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-sm-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-sm-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-sm-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-sm-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-sm-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-sm-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-sm-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-sm-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-sm-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-sm-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-sm-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-sm-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-sm-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 768px) {
    .flex-md-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-md-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-md-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-md-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-md-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-md-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-md-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-md-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-md-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-md-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-md-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-md-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-md-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-md-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-md-center {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-md-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-md-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-md-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-md-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-md-center {
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-md-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-md-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-md-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-md-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-md-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-md-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-md-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-md-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-md-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-md-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-md-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-md-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-md-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-md-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 992px) {
    .flex-lg-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-lg-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-lg-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-lg-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-lg-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-lg-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-lg-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-lg-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-lg-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-lg-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-lg-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-lg-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-lg-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-lg-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-lg-center {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-lg-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-lg-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-lg-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-lg-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-lg-center {
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-lg-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-lg-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-lg-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-lg-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-lg-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-lg-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-lg-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-lg-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-lg-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-lg-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-lg-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-lg-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-lg-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-lg-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }
  
  @media (min-width: 1200px) {
    .flex-xl-row {
      -ms-flex-direction: row !important;
      flex-direction: row !important;
    }
    .flex-xl-column {
      -ms-flex-direction: column !important;
      flex-direction: column !important;
    }
    .flex-xl-row-reverse {
      -ms-flex-direction: row-reverse !important;
      flex-direction: row-reverse !important;
    }
    .flex-xl-column-reverse {
      -ms-flex-direction: column-reverse !important;
      flex-direction: column-reverse !important;
    }
    .flex-xl-wrap {
      -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
    }
    .flex-xl-nowrap {
      -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
    }
    .flex-xl-wrap-reverse {
      -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
    }
    .flex-xl-fill {
      -ms-flex: 1 1 auto !important;
      flex: 1 1 auto !important;
    }
    .flex-xl-grow-0 {
      -ms-flex-positive: 0 !important;
      flex-grow: 0 !important;
    }
    .flex-xl-grow-1 {
      -ms-flex-positive: 1 !important;
      flex-grow: 1 !important;
    }
    .flex-xl-shrink-0 {
      -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
    }
    .flex-xl-shrink-1 {
      -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
    }
    .justify-content-xl-start {
      -ms-flex-pack: start !important;
      justify-content: flex-start !important;
    }
    .justify-content-xl-end {
      -ms-flex-pack: end !important;
      justify-content: flex-end !important;
    }
    .justify-content-xl-center {
      -ms-flex-pack: center !important;
      justify-content: center !important;
    }
    .justify-content-xl-between {
      -ms-flex-pack: justify !important;
      justify-content: space-between !important;
    }
    .justify-content-xl-around {
      -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
    }
    .align-items-xl-start {
      -ms-flex-align: start !important;
      align-items: flex-start !important;
    }
    .align-items-xl-end {
      -ms-flex-align: end !important;
      align-items: flex-end !important;
    }
    .align-items-xl-center {
      -ms-flex-align: center !important;
      align-items: center !important;
    }
    .align-items-xl-baseline {
      -ms-flex-align: baseline !important;
      align-items: baseline !important;
    }
    .align-items-xl-stretch {
      -ms-flex-align: stretch !important;
      align-items: stretch !important;
    }
    .align-content-xl-start {
      -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
    }
    .align-content-xl-end {
      -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
    }
    .align-content-xl-center {
      -ms-flex-line-pack: center !important;
      align-content: center !important;
    }
    .align-content-xl-between {
      -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
    }
    .align-content-xl-around {
      -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
    }
    .align-content-xl-stretch {
      -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
    }
    .align-self-xl-auto {
      -ms-flex-item-align: auto !important;
      align-self: auto !important;
    }
    .align-self-xl-start {
      -ms-flex-item-align: start !important;
      align-self: flex-start !important;
    }
    .align-self-xl-end {
      -ms-flex-item-align: end !important;
      align-self: flex-end !important;
    }
    .align-self-xl-center {
      -ms-flex-item-align: center !important;
      align-self: center !important;
    }
    .align-self-xl-baseline {
      -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
    }
    .align-self-xl-stretch {
      -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
    }
  }