/*==========  GLOBAL STYLES  ==========*/

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

html,
body,
button {
    -moz-osx-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
}

html {
    background-color: $colorBlack;
    overflow-x: hidden;
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
}

body {
    @extend %Montserrat-regular;
    font-size: $defaultFontSize;
    line-height: 1.5;
    color: $colorMain;
    overflow-x: hidden;

    &.nav-open {
        position: fixed;
        width: 100%;

        .l-navbar-mobile--hide {
            transform: translateY(0%);
        }

        .l-navbar__logo--hide {
            @media (min-width: 992px) {
                opacity: 1;
            }
        }
    }
}

ul, li {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none;

    &:not([class]) {
        color: inherit;
    }
}

audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

textarea {
    resize: vertical;
}

.grecaptcha-badge {display: none !important}
