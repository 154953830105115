.social-media {
    &__item,
    &__link {
        display: inline-block;
        vertical-align: middle;
    }

    &__item {
        &:not(:last-child) {
            margin-right: 20px;
        }
    }

    &__icon {
        width: 30px;
        height: 30px;

        transition: fill 0.2s ease;
    }
}