/**
*
* List
*
**/

// General
ul,
ol {
    padding: 0;
    margin: 0;
}

ul,
.list-ol {
    list-style-type: none;
}

.list-ul,
.list-ol {
    padding-top: 0.7em;
    padding-bottom: 0.7em;

    li {
        padding: 1px 0 1px 0;
        position: relative;
        margin-bottom: 2px;

        &:before {
            position: absolute;
            overflow: hidden;
            display: block;
            content: " ";
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
}

// Unordered list
.list-ul {
    padding-left: 30px;

    li {
        @extend %Montserrat-light;
        font-size: $defaultFontSize;

        &:before {
            top: 8px;
            left: -30px;
            content: "";
            width: 10px;
            height: 10px;
            border: 1px solid $colorGold;
            border-radius: 50%;
        }
    }
}

// Ordered list
.list-ol {
    counter-reset: item;
    padding-left: 30px;

    li {
        @extend %Montserrat-light;
        font-size: $defaultFontSize;

        &:before {
            left: -30px;

            width: 25px;
            content: counters(item, ".") ". ";
            counter-increment: item;

            text-align: left;
            font-family: 'Butler';
            font-weight: 300;
            line-height: 1.5;
            color: $colorGold;
        }
    }
}

// Other types of lists
.list-2-cols,
.list-3-cols,
.list-4-cols {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    
	li {
        width: 100%;

        margin-bottom: 10px;
	}
}

.list-2-cols {
    li {
        @media (min-width: 501px) {
            width: 47%;
        }
    }
}

.list-3-cols {
    li {
        @media (min-width: 768px) {
            width: 30%;
        }
    }
}

.list-4-cols {
    li {
        @media (min-width: 500px) and (max-width: 991px) {
            width: 50%;
        }

        @media (min-width: 992px) {
            width: 22%;
        }
    }
}

.content .list--no-bullet,
.list--no-bullet {
    li:before {
        display: none;
    }
}