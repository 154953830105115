/**
*
* Main footer
*
**/

.l-footer {
    margin-top: 100px;
    border-top: 1px solid $colorDarkGray;

    @media all and (max-width: 991px) {
        border-color: $colorGold;
    }

    &__row {

        @media all and (min-width: 992px) {
            display: flex;
        }
    }

    &__col {
        padding: 40px 20px;
        flex-basis: 473px;

        @media all and (max-width: 991px) {
            padding: {
                left: 0;
                right: 0;
            }
        }

        &--menu {
            /* IE10+ */
            @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                flex: 1 0 460px;
            }

            @media all and (min-width: 992px) {
                position: relative;
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: center;
            }

            @media all and (max-width: 991px) {
                position: relative;
                padding: 20px;
            }


            // WINGS FOR FOOTER
            &:after {
                z-index: -1;

                @media all and (max-width: 991px) {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 0;
                    left: -4%;
                    width: 100vw;
                    height: 1px;
                    background-color: $colorGold;
                }

                @media all and (min-width: 1501px) {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url(/images/footer-wing.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: right top;
                }

                @media all and (min-width: 992px) and (max-width: 1500px) {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                    background-image: url(/images/footer-wing.svg);
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: right center;
                }
            }
            
            &:before {
                @media all and (max-width: 991px) {
                    content: "";
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: -4%;
                    width: 100vw;
                    height: 1px;
                    background-color: $colorDarkGray;
                }
            }
        }

        &--border-gold {
            transition: border 1s ease;

            @media all and (min-width: 992px) {
                border: 1px solid $colorGold;
            }
        }

        &--flex {
            position: relative;
            display: flex;
            flex-direction: column;
        }

        &--contact-info  {
            @extend %Montserrat-light;
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-size: 18px;

            @media all and (min-width: 992px) {
                border-left: 1px solid $colorDarkGray;
                border-right: 1px solid $colorDarkGray;
            }

            a {
                position: relative;
                letter-spacing: 0.2em;
                color: $colorMain;
            }
        }

        &.active {
            position: relative;

            &:before {

                @media all and (min-width: 992px) {
                    content: "";
                    display: block;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    border: 1px solid $colorGold;
                    animation-name: fadeInBorder;
                    animation-duration: 1s;
                    animation-timing-function: ease;
                }
            }
        }
    }


    &__bp {
        @extend %Montserrat-light;
        margin-top: auto;
        text-transform: uppercase;
        color: $colorGray;
        font-size: 14px;
        letter-spacing: 0.2em;

        @media all and (max-width: 991px) {
            margin-top: 70px;
        }

        &-link {
            position: relative;
            color: $colorGray;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $colorMain;

            }
        }
    }

    &__menu {

        @media all and (min-width: 992px) {
            display: flex;
            flex-direction: column;
            align-content: space-between;
            flex-wrap: wrap;
            max-height: 205px;
            width: 335px;
            max-width: 80%;
        }

        @media all and (max-width: 991px) {
            text-align: center;
        }  
        
        &-item {
            text-align: center;
        }

        &-link {
            @extend %Montserrat-light;
            position: relative;
            padding: 20px 0;
            display: inline-block;
            font-size: 18px;
            text-transform: uppercase;
            color: $colorMain;
            transition: border-color 1s cubic-bezier(0.3, 0.00, 0.2, 1);

            &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 1px;
                bottom: 10px;
                background-color: $colorGold;
                transform: scaleX(0);
                transition: all 1s cubic-bezier(0.3, 0.00, 0.2, 1); 
            }
        
            &:hover{
                cursor: pointer;
        
                &:before {
                    transform: scaleX(1);
                }
            }

        }
    }

    &__bottom {
        @extend %Montserrat-light;
        padding: 40px 0;
        border-top: 1px solid $colorDarkGray;
        font-size: 14px;
        letter-spacing: 0.2em;
        color: $colorGray;
        text-align: center;

        p {
            margin: 0;
            line-height: 24px;
        }

        &-link {
            color: $colorGray;
            text-decoration: underline;
            transition: all 0.3s ease-in-out;

            &:hover {
                color: $colorMain;
            }
        }

        &-address {
            font-style: normal;
        }

        a {
            text-decoration: underline;
            transition: all 0.3s ease-in-out;
            color: $colorGray;

            &:hover {
                color: $colorMain;
            }
        }
    }

    .social {
        /* IE10+ */
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            flex-wrap: wrap;
        }
    }

    &--padded {
        padding: {
            top: 60px;
            bottom: 60px;
        }
    }

    &__link {
        @extend %Montserrat-regular;
        color: $colorMain;
        font-size: 12px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        transition: color 1s cubic-bezier(0.3, 0.00, 0.2, 1);
    }

    &--top-line {
        position: relative;

        @media all and (max-width: 991px) {
            border-top-color: $colorDarkGray;
        }

        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -1px;
            left: 0;
            width: 10vw;
            height: 1px;
            background-color: $colorGold;
        }
    }

    &__top-line {
        position: relative;
        top: -61px;
        height: 1px;
        width: 0;
        display: block;
        background-color: $colorGold;
        transition: width 1s ease-in-out;
    }

    &--active {
        .l-footer__col--border-gold {

            @media all and (min-width: 992px) {
                border-top-color: transparent;
                border-bottom-color: transparent;
                border-left-color: $colorDarkGray;
                border-right-color: $colorDarkGray;
            }
        }
    }

    &__logo {
        position: relative;

        img {
            pointer-events: none;
        }
    }

    &--link {
        display: block;

        &:hover {
            .l-footer__link {
                color: $colorGold;
            }
        }

        & * {
            pointer-events: none;
            color: $colorMain;
        }
    }

}

@keyframes fadeInBorder {
    from {
      opacity: 0;
    }
  
    to {
      opacity: 1;
    }
  }
  
