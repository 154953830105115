.sticky-cta {
    position: fixed;
    bottom: 48px;
    left:0;
    right:0;
    z-index: 100;

    padding: 0;
    font-size: 20px;
    font-weight: 700;

    @media all and (max-width: 560px) {
        bottom: 24px;
    }

    &__content {
        padding: 36px 108px 36px 48px;
        border: 1px solid #FFFFFF;

        @media all and (max-width: 990px) {
            padding-right: 48px;
        }

        @media all and (max-width: 560px) {
            padding: 20px 48px 20px 20px;
        }

        @media all and (min-width: 1201px) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &__text {
        margin: 0 0 24px;
        line-height: 1.1;
        font-family: "Butler", serif;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 0.2em;
        @include dynamicFontSize(24, 36, 480, 1600);

        @media all and (min-width: 1201px) {
            margin-bottom: 0;
            font-size: 24px;
        }

        .letters__word {
            margin-top: 0.3em;
        }
    }

    &__btn {
        white-space: nowrap;

        &--close {
            position: absolute;
            top: 50%;
            right: 38px;
            padding: 16px 2px;
            margin: 0;
            background: transparent;
            cursor: pointer;
            transform: translateY(-50%);

            @media all and (max-width: 990px) {
                top: 0;
                right: 12px;
                transform: none;
            }

            svg {
                transition: fill .5s;
            }

            &:hover svg {
                fill: $colorGold;
            }

            > * {
                pointer-events: none;
            }
        }
    }
}
