/**
*
* Top header
*
**/

.l-header {
    position: relative;

    &--mt {
        margin-top: 120px;

        &-none-desktop {

            @media all and (min-width: 992px) {
                margin-top: -2px;
            }
        }

        &-lg {
            margin-top: 240px;
        }
    }

    &--mb {
        margin-bottom: 120px;

        @media all and (max-width: 991px) {
            margin-bottom: 40px;
        }
    }

    &__container {
        position: relative;
        margin-right: auto;
        margin-left: auto;
        max-width: 865px;

        @media all and (max-width: 1200px) {
            max-width: 70vw;
        }

        &-wrapper {
            padding: 190px 0;

            @media all and (max-width: 901px) {
                padding: 95px 0;
            }

            @media all and (min-width: 992px) {
                margin-top: 55px;
            }
        }
    }

    &__logo {
        @extend .l-navbar__logo;

        @media all and (min-width: 991px) {
            padding-top: 20px;
            display: inline-block;
            background-color: $colorBlack;
            z-index: 1;
            opacity: 0;
        }

        &--cover {

            @media all and (min-width: 991px) {
                z-index: 100;
                opacity: 1;
            }
        }

        &-icon {
            @extend .l-navbar__logo-icon;
            display: block;

            @media all and (min-width: 991px) {
                width: 468px;
                height: 168px;
                border-left: 2px solid $colorBlack;
                border-right: 2px solid $colorBlack;
            }

            @media all and (max-width: 991px) {
                display: none;
            }
        }
    }

    &--overflow-hide {
        overflow: hidden;
    }

    &__time {
        margin: {
            top: 20px;
            bottom: 20px;
        }
        display: block;
        font-size: 14px;
        letter-spacing: 2.8px;
        text-transform: uppercase;
        color: $colorGray;
    }

    &__bg-img {
        padding-top: 40.70%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;

        img {
            display: none;
        }
    }

    &--with-filter {
        z-index: 11;
    }
}
