/**
*
* Button
*
**/

.btn {
    @extend %Montserrat-light;
    @include inline-block();
    position: relative;
    font-size: 18px;
    color: $colorMain;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    cursor: pointer;

    &--underline {
        padding: 40px 0;
        border-bottom: 1px solid $colorGold;
        transition: border-color 1s cubic-bezier(0.3, 0.00, 0.2, 1);

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: -1px;
            background-color: $colorMain;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 1s cubic-bezier(0.3, 0.00, 0.2, 1);
        }

        &:hover{
            cursor: pointer;

            &:before {
                visibility: visible;
                transform: scaleX(1);
            }
        }
    }

    &--bordered {
        display: inline-block;
        border: 1px solid $colorDarkGray;
        box-sizing: border-box;

        &:before,
        &:after {
            content:"";
            width: 0;
            height: 1px;
            position: absolute;
            transition: all cubic-bezier(0.3, 0.00, 0.2, 1);
            background: $colorGold;
            box-sizing: border-box;
        }

        &:before{
            left: 50%;
            top: 0;
            transition-duration: 1s;
        }

        &:after {
            left: 50%;
            bottom: 0;
            transition-duration: 1s;
        }

        &:hover {
            cursor: pointer;

            &:before {
                left: 0;
                width: 100%;
            }

            &:after {
                left: 0;
                width: 100%;
            }

            .btn__inner {
                &:before {
                    top: 0;
                    height: 100%;
                }

                &:after {
                    top: 0;
                    height: 100%;
                }
            }
        }

        .btn__inner {
            display: block;
            padding: 20px;
            box-sizing: border-box;

            &:before,
            &:after {
                content:"";
                width: 1px;
                height: 0;
                position: absolute;
                transition: all cubic-bezier(0.3, 0.00, 0.2, 1);
                background: $colorGold;
                box-sizing: border-box;
            }

            &:before{
                left: 0;
                top: 50%;
                transition-duration: 1s;
            }

            &:after {
                right: 0;
                top: 50%;
                transition-duration: 1s;
            }
        }

        @each $colorName, $color in $colors {
            &-#{$colorName} {
                border-color: $color;

                &:before,
                &:after {
                    background: $colorDarkGray;
                    height: 1px;
                }

                &:before {
                    top: -1px;
                }

                &:after {
                    bottom: -1px;
                }

                .btn__inner {
                    &:before,
                    &:after {
                        background: $colorDarkGray;
                        width: 1px;
                    }

                    &:before {
                        left: -1px;
                    }

                    &:after {
                        right: -1px;
                    }
                }
            }
        }
    }

    &--full-width {
        width: 100%;
    }

    &--wide {
        white-space: nowrap;

        @media all and (min-width: 420px) {
            width: 314px;
        }
    }

    &__inner {
        pointer-events: none;
    }

    &--file {
        display: block;
        text-align: center;
        @extend .btn--bordered;
        @extend .btn--full-width;

        .btn__inner {
            padding: 18px 20px;
        }
    }
}


// Size
.btn--big {
    font-size: 1.5em;
}

.btn--small {
    font-size: rem(14px);

    .btn__inner {
        padding: 8px 20px;
    }
}

// Colour
.btn--black {
    background: #000;
    color: #fff;

    &:hover {
        background: #666;
    }
}


button {

    &.btn {
        background: transparent;
    }
}
