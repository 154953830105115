/**
*
* Top navigation
*
**/

.l-navbar {
    position: fixed;
    top: 0;
    padding: 20px;
    text-align: center;
    width: 100%;
    overflow: hidden;
    z-index: 12;
    background-color: $colorBlack;
    transition: all 0.5s ease-out;

    @media all and (max-width: 991px) {
        position: absolute;
    }

    &__logo {
        position: relative;
        display: flex;
        justify-content: center;
        z-index: 1;
        cursor: pointer;
        transition: opacity 0.5s cubic-bezier(0.3, 0, 0.2, 1);

        &:before,
        &:after {
            content: "";
            position: absolute;
            left: 0;
            display: block;
            width: 100%;
            height: 10px;
            z-index: 10;
            background-color: $colorBlack;
        }

        &:before {
            // @media all and (min-width: 992px) {
                top: -4px;
            // }
        }

        &:after {
            bottom: -4px;

            @media all and (max-width: 991px) {
                bottom: -6px;
            }
        }

        &-icon {
            position: relative;
            display: block;
            width: 300px;
            height: 108px;
            background: url(/images/logo-mask-2021.svg);
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;
            overflow: hidden;


            @media all and (max-width: 991px) {
                width: 245px;
                height: 88px;
            }

            video {
                position: absolute;
                left: 1px;
                top: 0;
                z-index: -1;
                width: 98%;
                pointer-events: none;
            }

            span {
                opacity: 0;
                pointer-events: none;
            }
        }

        &--hide {

            @media all and (min-width: 992px) {
                opacity: 0;
            }
        }
    }


    &__burger {
        position: relative;
        background: transparent;
        outline: none;

        &:before,
        &:after {
            @media all and (max-width: 991px) {
                content:"";
                width: 0;
                height: 1px;
                position: absolute;
                transition: all cubic-bezier(0.3, 0.00, 0.2, 1);
                background: $colorGold;
                box-sizing: border-box;
            }

        }

        &:before{
            @media all and (max-width: 991px) {
                left: 50%;
                top: 0;
                transition-duration: 1s;
            }
        }

        &:after {
            @media all and (max-width: 991px) {
                left: 50%;
                bottom: 0;
                transition-duration: 1s;
            }
        }

        &:hover {
            cursor: pointer;

            &:before {
                left: 0;
                width: 100%;
            }

            &:after {
                left: 0;
                width: 100%;
            }

            .l-navbar__burger-text {
                &:before {
                    top: 0;
                    height: 100%;
                }

                &:after {
                    top: 0;
                    height: 100%;
                }
            }
        }

        @media all and (min-width: 992px) {
            padding: 10px;
            top: 50%;
            margin-top: -21px;
            position: absolute;
            right: 80px;
            z-index: 1;
            cursor: pointer;
        }


        @media all and (max-width: 991px) {
            margin: 10px auto 0;
            padding: 15px;
            max-width: 315px;
            width: 315px;
            border: 1px solid $colorDarkGray;
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            cursor: pointer;
        }

        &.open {

            .burger {


                @media all and (min-width: 992px) {
                    .burger-item--first {
                        transform: translateY(10px) rotate(-45deg);
                    }

                    .burger-item--second {
                        transform: translateY(-10px) rotate(45deg);
                    }
                }
            }
        }


        &-text {
            @extend %Montserrat-light;
            margin-right: 12px;
            color: $colorMain;

            &:before,
            &:after {
                content:"";
                width: 1px;
                height: 0;
                position: absolute;
                transition: all cubic-bezier(0.3, 0.00, 0.2, 1);
                background: $colorGold;
                box-sizing: border-box;
            }

            &:before{
                left: 0;
                top: 50%;
                transition-duration: 1s;
            }

            &:after {
                right: 0;
                top: 50%;
                transition-duration: 1s;
            }

            @media all and (min-width: 992px) {
                display: none;
            }
        }
    }

    &--scrolled {
        transform: translateY(-100%);
    }

    &-mobile {
        justify-content: center;
        align-items: center;

        transition: all 0.5s ease-out;

        @media all and (max-width: 991px) {
            transform: translateY(0%);
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            display: flex;
            background-color: $colorBlack;
            z-index: 12;
            border-bottom: 1px solid $colorDarkGray;

            padding: {
                top: 10px;
                bottom: 10px;
            }
        }

        &__logo {
            width: 40px;
            height: 29px;
        }

        .burger {
            position: absolute;
            right: 25px;
        }

        &--hide {

            @media all and (max-width: 991px) {


                transform: translateY(-100%);

            }
        }
    }
}
