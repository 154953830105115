.tile {
    margin-top: 40px;
    display: block;
    color: $colorMain;
    border: 1px solid $colorDarkGray;

    &:hover {

        .tile__img-item,
        .tile__img-box {
            transform: scale(1.1);
        }

        .tile__content:after {
            visibility: visible;
            transform: scaleX(1);
        } 
    }

    &-section {
        @extend %Montserrat-light;
        font-size: 16px;
    }

    &-container {
        margin-bottom: 80px;

        @media all and (max-width: 991px) {
            margin-bottom: 40px;
        }
    }

    &__img {
        position: relative;;
        overflow: hidden;
        pointer-events: none;

        &-item {
            transition: transform 1s cubic-bezier(0.3, 0.00, 0.2, 1);
        }

        &-curtain {
            position: absolute;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
            z-index: 2;
        }

        &-box {
            position: relative;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: top;
            padding-bottom: 75%;
            transition: transform 1s cubic-bezier(0.3, 0.00, 0.2, 1);

            &--height-sm {
                padding-bottom: 65%;
                height: 100%;
            }

            img {
                display: none;
            }
        }
    }

    &__headline {
        margin-bottom: 25px;
    }
    
    &--flex {

        @media all and (min-width: 992px) {
            display: flex; 
        }

        .tile__img {
            margin: 0;
            flex: 1 1 42.86%;
        }

        .tile__content {
            flex: 1 1 57.14%;
        }

        &-tablet {

            @media all and (min-width: 991px) and (max-width: 1199px) {
                display: flex;
            }

            .tile__img {

                @media all and (min-width: 991px) and (max-width: 1199px) {
                    margin: 0;
                    flex: 1 1 42.86%;
                }
            }
    
            .tile__content {
                @media all and (min-width: 991px) and (max-width: 1199px) {
                    flex: 1 1 57.14%;
                }
            }
        }
    }

    &__content {
        position: relative;
        padding: 35px 40px;
        background-color: $colorBlack;
        pointer-events: none;

        @media all and (max-width: 574px) {
            padding: 20px;
        }

        &:before,
        &:after {
            content: "";
            position: absolute;
            width: 80%;
            height: 1px;
            bottom: 0px;
            left: 50%;
            margin-left: -40%;
        }

        &:before {
            background: $colorGold;
        }

        &:after {
            background-color: $colorMain;
            visibility: hidden;
            transform: scaleX(0);
            transition: all 1s cubic-bezier(0.3, 0.00, 0.2, 1);
            z-index: 2;
        }

        time {
            margin: {
                top: 20px;
                bottom: 20px;
            }
            display: block;
            font-size: 14px;
            letter-spacing: 2.8px;
            text-transform: uppercase; 
            color: $colorGray;
        }
    }

    + .tile {

        @media all and (max-width: 1199px) {
            margin-bottom: 40px;
        }
    }

    &__btn {
        @extend %Montserrat-light;
        display: inline-block;
        margin-top: 25px;
        font-size: 18px;
        letter-spacing: 0.2em;
        text-transform: uppercase;

        @media all and (min-width: 481px) and (max-width: 768px) {
            font-size: 16px;
        }

        @media all and (max-width: 480px) {
            font-size: 14px;
        }
    }

    &__label {
        font-size: 14px;
        letter-spacing: 2.8px;
        line-height: 24px;
        text-transform: uppercase;
        color: $colorGray;
    }

    &--content {
        @extend .tile;
        @extend .tile__content;
        @extend %Montserrat-light;
        margin-top: 0;
        height: 100%;
        display: block;
        font-size: 16px;
        cursor: pointer;
        pointer-events: auto;

        &:hover {
            
            &:after {
                visibility: visible;
                transform: scaleX(1);
            } 
        }
    }

    &-wrapper {
        margin-top: 40px;
    }

    &--p-lg {

        @media all and (min-width: 768px) {
            padding: 60px;
        }
    }

    &--underline-width-sm {
        position: relative;

        &:before,
        &:after {
            width: 40%;
            height: 1px;
            bottom: 0px;
            left: 50%;
            margin-left: -20%;
            
        }
    }

    &--full-height {
        margin-top: 0;
        height: 100%;
        position: relative;

        .tile__content {
            position: static;
        }
    }
}