.section {
    position: relative;

    // default padding
    padding: {
        top: 80px;
        bottom: 80px;
    }

    @media all and (max-width: 991px) {
        padding: {
            top: 40px;
            bottom: 40px;
        }

    }

    // padding variations
    &--p-small {
        &-top {
            padding-top: 40px;
        }

        &-bottom {
            padding-bottom: 40px;
        }
    }

    &--p-medium {
        &-top {
            padding-top: 60px;
        }

        &-bottom {
            padding-bottom: 60px;
        }
    }

    &--p-large {
        &-top {

            @media all and (min-width:991px) {
                padding-top: 100px;
            }
        }

        &-bottom {

            @media all and (min-width:991px) {
                padding-bottom: 100px;
            }
        }
    }

    &--p-mlarge {
        &-top {

            @media all and (min-width:991px) {
                padding-top: 120px;
            }
        }

        &-bottom {

            @media all and (min-width:991px) {
                padding-bottom: 120px;
            }
        }
    }

    &--p-xlarge {
        &-top {

            @media all and (min-width:991px) {
                padding-top: 190px;
            }
        }

        &-bottom {

            @media all and (min-width:991px) {
                padding-bottom: 190px;
            }
        }
    }

    &--p-xxlarge {
        &-top {

            @media all and (min-width:991px) {
                padding-top: 220px;
            }
        }

        &-bottom {

            @media all and (min-width:991px) {
                padding-bottom: 220px;
            }
        }
    }

    &--p-none {
        &-top {
            padding-top: 0;
        }

        &-bottom {
            padding-bottom: 0;
        }
    }

    // header + footer
    &__header {

        p {
            @extend %Montserrat-light;
            font-size: 18px;
            text-transform: uppercase;
            letter-spacing: 0.2em;

            &:last-child {
                margin-bottom: 5em;
            }

        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-top: 0.6em;
        }

        &--fixed-width {
            padding-bottom: 0;

            @media all and (max-width: 767px) {
                max-width: 80%;
            }

        }
    }

    &__footer {
        padding-top: 2em;
    }

    // useful
    &--vertically-aligned {
        display: flex;
        align-items: center;
    }

    &--btns-on-bottom {
        [class*="col-"] {
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            p:last-of-type {
                justify-content: flex-end;
            }
        }
    }

    &--border {

        &-to-right {
            &:before {
                content: "";
                width: calc((100vw - ((100vw - 1350px)/2)));
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                border: 1px solid $colorDarkGray;

                @media all and (min-width: 575px) and (max-width: 1500px) {
                    width: calc(100vw - 60px);
                }

                @media all and (max-width: 901px) {
                    width: calc(100vw - 60px);
                    right: 50%;
                    transform: translateX(50%);
                }
            }
        }

        &-to-left {

            &:before {
                content: "";
                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                left: -1px;
                border: 1px solid $colorDarkGray;

                @media all and (min-width: 1501px) {
                    width: calc((100vw - ((100vw - 1210px)/2)));
                }

                @media all and (min-width: 991px) and (max-width: 1500px) {
                    width: calc( 100vw/12 * 10.5);
                }
            }
        }



        &-center {

            &:before {
                content: "";
                width: calc( 92% / 12 *11);

                height: 100%;
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                border: 1px solid $colorDarkGray;

                @media all and (min-width: 1750px) {
                    width: calc( 1456px / 12 *11);
                }

                @media all and (max-width: 991px) {
                    width: 100vw;
                    right: 50%;
                    margin-left: 50%;
                    transform: translate(50%);
                }
            }

            &--left {

                @media all and (min-width: 992px) {

                    &:before {
                        left: 0;

                    }

                }

            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {

                @media all and (max-width: 991px) {
                    margin-top: 0.6em;
                }
            }
        }

        &-img {
            &:before {
                background-image: url('/images/xavio-hero-wings-opacity.svg');
                background-size: 100% auto;
                background-position: 0 50%;
                background-repeat: no-repeat;
            }
        }

        &-repeated-img {
            &:before {
                background-image: url('/images/wings-pattern-contained.svg');
                background-size: cover;
                background-position: 0 0;
                background-repeat: no-repeat;
            }
        }
    }

    &--outline {

        &:before {
            content: "";
            width: calc( (100vw - (100vw - 1456px))/12 * 10);
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            right: 50%;
            transform: translateX(50%);
            border: 1px solid $colorDarkGray;

            @media all and (max-width: 1500px) {
                width: calc( 92% / 12 * 11);
            }
        }
    }
}
